import classNames from 'classnames';
import PropTypes from 'prop-types';

import { WWW_URL } from '@pumpkincare/config';
import {
  CONTACT_WELLNESS_EMAIL,
  CONTACT_WELLNESS_EMAIL_LINK,
  formatISODate,
  FORMATTED_WELLNESS_PHONE_NUMBER,
  getEffectiveDate,
  getIllnessEffectiveDate,
  WELLNESS_PHONE_NUMBER_LINK,
} from '@pumpkincare/shared';
import { Body2, FAQSet, Link, Typography } from '@pumpkincare/shared/ui';

import styles from './checkout-faq.css';

function CheckoutFAQ({
  policyState,
  vetName,
  isPrevSelected,
  isCAModelLaw,
  hasWellnessQuotePet,
  waitingPeriods,
}) {
  const canICancelMyInsurance = {
    question: <span>Can I cancel my Pumpkin Pet Insurance Plan?</span>,
    answer: (
      <>
        <Body2>
          {policyState === 'NY' ? (
            <>
              Yes, you can cancel your Pumpkin Pet Insurance plan any time during
              your pet's year-long policy period and get a prorated refund for the
              remaining days in the month.
            </>
          ) : (
            <>
              Yes, you can cancel your Pumpkin Pet Insurance plan. Cancel in the
              first 15 days and get a full refund as long as you have not yet filed
              any covered claims. If you cancel after 15 days and/or have already
              filed a covered claim, you’ll get a prorated refund for the remaining
              days in the month.
            </>
          )}
        </Body2>
        <Body2>
          See{' '}
          <Link
            href={`${WWW_URL}/insurancepolicy`}
            rel='noopener noreferrer'
            target='_blank'
          >
            <span className={classNames(Typography.body2, styles.linkFont)}>
              sample insurance policy
            </span>
          </Link>{' '}
          for full terms.
        </Body2>
      </>
    ),
  };

  const canICancelMyWellness = {
    question: <span>Can I cancel my Pumpkin Wellness Club?</span>,
    answer: (
      <>
        <Body2>
          {
            'Yes, you may cancel your Pumpkin Wellness Club membership by calling us at '
          }
          <Link href={WELLNESS_PHONE_NUMBER_LINK}>
            <span className={classNames(Typography.body2, styles.linkFont)}>
              {FORMATTED_WELLNESS_PHONE_NUMBER}
            </span>
          </Link>
          {' or emailing your cancellation request to '}
          <Link href={CONTACT_WELLNESS_EMAIL_LINK}>
            <span className={classNames(Typography.body2, styles.linkFont)}>
              {CONTACT_WELLNESS_EMAIL}
            </span>
          </Link>
          . However, whether you receive a refund or owe outstanding fees will depend
          on a few different factors. Please find the scenario below that applies to
          you for more details.
        </Body2>
        <br />
        <Body2 isBold>Monthly Paying Members</Body2>
        <br />
        <Body2>
          I have not received reimbursement for any Pumpkin Wellness Club membership
          benefits.
          <ul>
            <li>
              Cancel in the first 30 days after the beginning of the initial or
              renewal term and get a full refund of paid fees to you with no
              obligation to pay any future monthly fees.
            </li>
            <li>
              Cancel more than 30 days after the beginning of the initial or renewal
              term and get a pro-rated refund of only your current month’s paid fees
              (based on the number of days left in the month) with no obligation to
              pay any future monthly fees.
            </li>
          </ul>
        </Body2>
        <Body2>
          I have received reimbursement for one or more Pumpkin Wellness Club
          membership benefits.
          <ul>
            <li>
              Cancel any time after the beginning of the initial or renewal term, and
              you will owe outstanding fees equal to the difference of the total
              amount paid to you for any membership benefit(s) during the term year
              and the total amount paid by you in monthly membership fees during the
              same term year. After a one-time charge to your card on file for any
              outstanding fees, you will have no obligation to pay any future monthly
              fees. There will be no refund to you if the amount of outstanding fees
              is a negative number.
            </li>
          </ul>
        </Body2>
        <br />
        <Body2 isBold>Annual Paying Members</Body2>
        <br />
        <Body2>
          I have not received reimbursement for any Pumpkin Wellness Club membership
          benefits.
          <ul>
            <li>
              Cancel in the first 30 days after the beginning of the initial or
              renewal term and get a full refund of paid fees to you with no
              obligation to pay any future annual fees.
            </li>
            <li>
              Cancel more than 30 days after the beginning of the initial or renewal
              term and no refund will be provided.
            </li>
          </ul>
        </Body2>
        <Body2>
          I have received reimbursement for one or more Pumpkin Wellness Club
          membership benefits.
          <ul>
            <li>
              Cancel any time after the beginning of the initial or renewal term and
              no refund will be provided.
            </li>
          </ul>
        </Body2>
        <Body2>
          {'See our '}
          <Link
            href={`${WWW_URL}/sample-plan`}
            rel='noopener noreferrer'
            target='_blank'
          >
            <span className={classNames(Typography.body2, styles.linkFont)}>
              Membership Agreement
            </span>
          </Link>
          {' for full cancellation & refund terms'}
        </Body2>
      </>
    ),
  };

  const canICancelPEP = {
    question: <span>Can I cancel my Preventive Essentials?</span>,

    answer: (
      <>
        <Body2>
          Yes, you can cancel your Preventive Essentials at any time. If you have not
          received a refund for a Preventive Essentials Claim, cancel in the first 30
          days and get a full refund. Cancel after 30 days and get a prorated refund
          for the remaining days in the month.
        </Body2>

        <Body2>
          If you have received a refund for one or more Preventive Essential claims,
          cancel any time during the year term of your Customer Agreement, and
          Pumpkin will charge your card for an outstanding balance equal to the
          difference of the amount paid back to you for your claim(s) and the amount
          paid by you in monthly fees this term.
        </Body2>

        <Body2>
          See{' '}
          <Link
            href={`${WWW_URL}/customeragreement`}
            rel='noreferrer'
            target='_blank'
          >
            <span className={classNames(Typography.body2, styles.linkFont)}>
              Preventive Essentials Customer Agreement
            </span>
          </Link>{' '}
          for full terms.
        </Body2>
      </>
    ),
  };

  const isMyVetsCareCovered = {
    question: <span>Is my vet's care covered?</span>,
    answer: (
      <Body2>
        Yes! Unlike a human health insurance HMO, your Pumpkin Pet Insurance plan's
        coverage isn't limited to a "network" of health care providers. Since you get
        paid back directly, your pet is free to receive treatment from any licensed
        veterinarian, specialist, emergency clinic or hospital you choose in the U.S.
        or Canada!
        {vetName ? (
          <> That includes your current veterinarian at {vetName}.</>
        ) : null}
      </Body2>
    ),
  };

  const isSpayAndNeuterCovered = {
    question: <span>Is spay {'&'} neuter covered?</span>,
    answer: (
      <>
        <Body2>
          Nope. Since pet insurance is "accident {'&'} illness insurance," Pumpkin
          plans won't cover elective procedures performed for general wellness
          maintenance like spaying or neutering.
        </Body2>

        <Body2>
          For a full list of what Pumpkin insurance plans do and don't cover,
          checkout out a{' '}
          <Link
            href={`${WWW_URL}/insurancepolicy`}
            rel='noopener noreferrer'
            target='_blank'
          >
            <span className={classNames(Typography.body2, styles.linkFont)}>
              sample policy.
            </span>
          </Link>
        </Body2>
      </>
    ),
  };

  const waitingPeriodForInsurance = {
    question: <span>Is there a waiting period?</span>,

    answer: (
      <>
        {waitingPeriods?.accident?.period === 0 ? (
          <>
            <Body2>
              There is no waiting period for accidents, your pet's insurance coverage{' '}
              starts the day after you enroll.
            </Body2>
            <br />
            <Body2>
              However, there is a {waitingPeriods?.illness?.period}-day waiting
              period before coverage starts for illnesses, and it starts the day
              after you enroll.
            </Body2>
          </>
        ) : (
          <>
            Yes. There is a{' '}
            {Math.max(
              waitingPeriods?.accident?.period,
              waitingPeriods?.illness?.period
            )}
            -day waiting period before your pet's insurance coverage kicks in for all
            accidents & illnesses, and it starts the day after you enroll.
          </>
        )}
        <br />
        <Body2>
          {waitingPeriods?.accident?.period === 0 ? (
            <>
              So if you purchase a Pumpkin Pet Insurance plan today on [
              {formatISODate(new Date(), { format: 'MMMM Do, YYYY' })}], your
              coverage for accidents would start tomorrow [{getEffectiveDate()}].
              Your {waitingPeriods?.illness?.period}-day illness waiting period would
              also begin tomorrow and end on [
              {getIllnessEffectiveDate(waitingPeriods?.illness?.period)}], the day
              your pet's insurance coverage for illnesses would officially start.
            </>
          ) : (
            <>
              So if you purchase a Pumpkin Pet Insurance plan today on{' '}
              {formatISODate(new Date(), { format: 'MMMM Do, YYYY' })}, your{' '}
              {Math.max(
                waitingPeriods?.accident?.period,
                waitingPeriods?.illness?.period
              )}
              -day waiting period would start tomorrow and end on{' '}
              {getIllnessEffectiveDate()}, the day your pet's insurance coverage
              would officially start.
            </>
          )}
        </Body2>
      </>
    ),
  };

  const preExistingCondition = {
    question: <span>What qualifies as a pre-existing condition?</span>,

    answer: (
      <>
        <Body2>
          If your pet has a condition that occurred or showed symptoms before
          coverage starts or during the{' '}
          {Math.max(
            waitingPeriods?.accident?.period,
            waitingPeriods?.illness?.period
          )}
          -day waiting period, it's considered "pre-existing" and won't be covered by
          your Pumpkin insurance plan - even if the condition has not been formally
          diagnosed by your vet.
        </Body2>

        <Body2>
          However, if the pre-existing condition is curable (e.g., UTI, paw
          fracture), was cured, and has been free of symptoms and treatment for 180
          days, your Pumpkin plan would cover it if it occurs again in the future,
          with the exception of knee {'&'} hind leg ligament conditions. If a knee or
          hind leg ligament condition occurs before the coverage start date or during
          the waiting period, any future ones won't be covered.
        </Body2>

        <Body2>
          All that said, having an incurable pre-existing condition, or a knee or
          hind leg ligament condition, doesn't mean you shouldn't join the Pumpkin
          pack! You can always enroll your pet and get covered for new conditions
          that develop down the road.
        </Body2>
      </>
    ),
  };

  const allQuestions = [
    canICancelMyInsurance,
    isCAModelLaw && hasWellnessQuotePet ? canICancelMyWellness : null,
    isPrevSelected ? canICancelPEP : null,
    isMyVetsCareCovered,
    isSpayAndNeuterCovered,
    policyState === 'ME' ? null : waitingPeriodForInsurance,
    preExistingCondition,
  ].filter(question => question);

  return (
    <>
      <h3>More Info</h3>
      <FAQSet questionSet={allQuestions} />
    </>
  );
}

CheckoutFAQ.propTypes = {
  policyState: PropTypes.string,
  vetName: PropTypes.string,
  isPrevSelected: PropTypes.bool,
  isCAModelLaw: PropTypes.bool,
  hasWellnessQuotePet: PropTypes.bool,
  waitingPeriods: PropTypes.object,
};

export default CheckoutFAQ;

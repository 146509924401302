import { useHistory } from 'react-router';
import Store from 'store';

import {
  analyticsIdentify,
  GAQuoteFlowSubmitNameAndEmail,
  getAnonymousId,
  setTrackedCustomProps,
} from '@pumpkincare/analytics';
import { HOME_URL } from '@pumpkincare/config';
import { getDiscountId, useDiscount } from '@pumpkincare/discounts';
import { patchIdentity } from '@pumpkincare/identity';
import {
  getMarketingAttribution,
  setMarketingAttributionProperty,
} from '@pumpkincare/marketing';
import {
  getQuoteId,
  getQuoteTrackingId,
  patchQuoteVetAttribution,
  registerQuote,
  useQuote,
  useQuotesActiveId,
} from '@pumpkincare/quotes';
import {
  formatCleanedPhoneNumber,
  IDENTITY_LOCAL_STORAGE_KEY,
  useBooleanInput,
} from '@pumpkincare/shared';

import { Paths } from '../../../app-shell';

function useSubmitRegister() {
  const { data: quoteData } = useQuote();
  const quoteId = getQuoteId(quoteData);

  const { data: discountData } = useDiscount();
  const discountId = getDiscountId(discountData);

  const { activeId } = useQuotesActiveId();
  const trackingId = getQuoteTrackingId(quoteData) || getAnonymousId();

  const history = useHistory();

  const [isLoading, toggleIsLoading] = useBooleanInput(false);

  function submit(payload) {
    const {
      email,
      firstName,
      lastName,
      policyZipCode,
      phone,
      vet,
      shouldSubmitDiscount,
    } = payload;

    GAQuoteFlowSubmitNameAndEmail();
    setTrackedCustomProps({ email });
    analyticsIdentify(payload, trackingId);

    // update vet info
    analyticsIdentify(
      vet.id
        ? {
            VETCLINICNAME: vet.vet_name,
            VETCLINICID: vet.id,
          }
        : {
            VETCLINICNAME: vet.vet_name ? 'Other Vet' : 'No Vet',
          },
      trackingId
    );

    const params = {
      quoteId,
      activeId,
      trackingId,
      firstName,
      lastName,
      email,
      policyZipCode,
    };

    if (shouldSubmitDiscount) {
      params.discountId = discountId;
    }

    if (vet?.id) {
      patchQuoteVetAttribution(quoteId, { vet_id: vet.id });
    }

    toggleIsLoading();

    return registerQuote(params).then(() => {
      const marketing = getMarketingAttribution();
      const traits = {
        QUOTEURL: `${HOME_URL}/plan-selection/${quoteId}`,
        QUOTEID: quoteId,
        email,
        marketing,
      };

      toggleIsLoading();

      analyticsIdentify(traits, trackingId);
      setMarketingAttributionProperty({ isGoingToPlanSelection: true });

      patchIdentity({
        id: Store.get(IDENTITY_LOCAL_STORAGE_KEY),
        email,
        last_name: lastName,
        first_name: firstName,
        phone_number: formatCleanedPhoneNumber(phone),
      });

      history.push(Paths.PlanSelection);
    });
  }

  return { submit, isLoading };
}

export default useSubmitRegister;

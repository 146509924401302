import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import {
  getQuoteCompletePets,
  getQuotePets,
  getQuotePolicyState,
  useQuote,
} from '@pumpkincare/quotes';
import {
  formatISODate,
  getEffectiveDate,
  getIllnessEffectiveDate,
  getIsLoggedIn,
} from '@pumpkincare/shared';
import { Body2, LegalBody, Typography } from '@pumpkincare/shared/ui';

import Summary from './summary';

import styles from './plan-review.css';

function PlanReview({ waitingPeriods, wellnessPets, setWellnessPets }) {
  const { punks2110CaliforniaModelLaw } = useFlags();
  const { data: quoteData } = useQuote();
  const pets = getIsLoggedIn()
    ? getQuotePets(quoteData)
    : getQuoteCompletePets(quoteData);

  const hasPrevent = pets.some(pet => pet.hasPrevent);

  const effectiveDate = getEffectiveDate();
  const illnessEffectiveDate = getIllnessEffectiveDate({
    period: waitingPeriods?.illness?.period,
  });
  const isAccidentAvailableOnEffective = waitingPeriods?.accident?.period === 0;
  const isModelLaw =
    waitingPeriods?.accident?.period === waitingPeriods?.illness?.period;

  const isCAModelLaw =
    punks2110CaliforniaModelLaw && getQuotePolicyState(quoteData) === 'CA';
  const hasWellnessQuotePet = !!wellnessPets?.length;

  return (
    <>
      <Summary wellnessPets={wellnessPets} setWellnessPets={setWellnessPets} />

      <div>
        <h4>Important Dates</h4>

        <div>
          <div>
            <Body2 isBold className={styles.dateSpacing}>
              {formatISODate(new Date(), { format: 'MMMM Do, YYYY' })}
            </Body2>

            <div className={styles.verticalLine}>
              <LegalBody className={styles.importantDatesFont}>
                <span className={Typography.bold}>Enrollment Date</span>
                <br />
                Yay! Today you decided to enroll in a Pumpkin plan and join the
                Pumpkin Pack.
              </LegalBody>
            </div>

            {isCAModelLaw && hasWellnessQuotePet ? (
              <>
                <br />
                <div className={styles.verticalLine}>
                  <LegalBody className={styles.importantDatesFont}>
                    <span className={Typography.bold}>
                      Pumpkin Wellness Club Start Date
                    </span>
                    <br />
                    {
                      'You can use your Wellness Club benefits starting the day you enroll.'
                    }
                  </LegalBody>
                </div>
              </>
            ) : null}
          </div>

          <div>
            <Body2 isBold className={styles.dateSpacing}>
              {effectiveDate}
            </Body2>

            <div className={styles.verticalLine}>
              <LegalBody className={styles.importantDatesFont}>
                <span className={Typography.bold}>Insurance Effective Date</span>
                <>
                  <br />
                  The day after you enroll in pet insurance.
                </>
              </LegalBody>
            </div>

            {isAccidentAvailableOnEffective ? (
              <div>
                <br />
                <div className={styles.verticalLine}>
                  <LegalBody className={styles.importantDatesFont}>
                    <span className={Typography.bold}>
                      Accident Coverage Start Date
                    </span>
                    <br />
                    The day your accident coverage starts and your illness waiting
                    period begins.
                  </LegalBody>
                </div>
              </div>
            ) : null}

            {hasPrevent ? (
              <>
                <br />
                <div className={styles.verticalLine}>
                  <LegalBody className={styles.importantDatesFont}>
                    <span className={Typography.bold}>
                      Preventive Essentials Start Date
                    </span>
                    <br />
                    The day after you enroll in Preventive Essentials and when you{' '}
                    can use those benefits.
                  </LegalBody>
                </div>
              </>
            ) : null}
          </div>

          <Body2 isBold className={styles.dateSpacing}>
            {illnessEffectiveDate}
          </Body2>

          <div className={styles.verticalLine}>
            <LegalBody className={styles.importantDatesFont}>
              <span className={Typography.bold}>
                {isModelLaw ? 'Insurance' : 'Illness'} Coverage Start Date
              </span>
              <br />
              The day your {isModelLaw ? 'pet' : 'illness'} insurance coverage kicks
              in after the {waitingPeriods?.illness?.period}-day illness waiting
              period.
            </LegalBody>
          </div>
        </div>
      </div>
    </>
  );
}

PlanReview.propTypes = {
  waitingPeriods: PropTypes.shape({
    accident: PropTypes.shape({
      period: PropTypes.number,
    }),
    illness: PropTypes.shape({
      period: PropTypes.number,
    }),
  }),
  wellnessPets: PropTypes.array,
  setWellnessPets: PropTypes.func,
};

export default PlanReview;

import { useCallback } from 'react';

import { analyticsTrack, errorBannerShow } from '@pumpkincare/analytics';
import { ErrorListBanner } from '@pumpkincare/shared/ui';

import { useErrors, useErrorsVisibility } from './checkout-provider';

function Header() {
  const allErrors = useErrors();
  const [errorsVisibility, setErrorsVisibility] = useErrorsVisibility();
  // eslint-disable-next-line no-unused-vars
  const { termsChecked, wellnessTermsChecked, ...errors } = allErrors.current;

  const handleBannerError = useCallback(banner => {
    analyticsTrack(errorBannerShow(banner));
  }, []);

  return (
    <>
      {errorsVisibility && Object.values(errors).length ? (
        <ErrorListBanner
          errors={Object.values(errors)}
          onClose={() => setErrorsVisibility(false)}
          onError={handleBannerError}
        />
      ) : null}
    </>
  );
}

export default Header;

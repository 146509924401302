export const COPAY = 'copay';
export const ANNUAL_LIMIT = 'annual_limit';
export const UNLIMITED_ANNUAL_LIMIT = 'Unlimited';
export const DEDUCTIBLE = 'deductible';
export const LEVER_NAMES = ['copays', 'limits', 'deductibles'];

export const PUMPKIN_PREVENT_1 = 'Pumpkin Prevent 1.0';
export const PUMPKIN_PREVENT_2 = 'Pumpkin Prevent 2.0';
export const PUPPY_PREVENT_2 = 'Puppy Prevent';
export const PUMPKIN_PREVENT_3 = 'Pumpkin Prevent 3.0';
export const PUPPY_PREVENT_3 = 'Puppy Prevent 3.0';
export const PUMPKIN_PREVENT_3_1 = 'Pumpkin Prevent 3.1';
export const PUPPY_PREVENT_3_1 = 'Puppy Prevent 3.1';

export function getQueryParam(variable, search = window.location.search) {
  const query = search.substring(1);
  const params = query.split('&');

  for (let i = 0; i < params.length; i++) {
    const pair = params[i].split('=');

    if (pair[0] === variable) {
      return pair[1] || true;
    }
  }

  return false;
}

export function convertIfBool(value) {
  return value === 'true' || (value === 'false' ? false : value);
}

/*
 * Cleans a phone number to be only numbers and a + if internationally coded
 * @param {string} str - string to be formatted
 */
export function formatCleanedPhoneNumber(str) {
  // this returns a clean string with only numbers and a + sign for an international code
  // 99.5% of our BE stored phones are 12 characters long with a +1 in front
  const international = `${str}`.replace(/[^+\d]/g, '');
  const hasPlus = !!international.match(/^\+/);

  // international numbers on FE are more of a edge case, so we're not going to handle them for now with a heavy library
  return hasPlus ? international.slice(2) : international;
}

/*
 * Formats a valid phone number to (xxx) xxx-xxxx, otherwise returns original input
 * @param {string} str - string to be formatted
 */
export function formatPhoneNumber(str) {
  const cleaned = formatCleanedPhoneNumber(str);

  // Check if the input is of correct length
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  return match ? `(${match[1]}) ${match[2]}-${match[3]}` : str;
}

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { WWW_URL } from '@pumpkincare/config';
import {
  Body1,
  ButtonStyles,
  KeyboardArrowDown,
  LegalBody,
  Link,
  NotifyMessage,
  SlideOutPanel,
  Typography,
} from '@pumpkincare/shared/ui';

import WellnessFAQ from '../../../../../../plans/view/ecomm-view-v2/wellness-faq';
import WellnessPicker from '../../../../../../plans/view/wellness-picker';

import styles from './wellness-slide-out-panel.css';

function WellnessSlideOutPanel({
  isSlideOpen,
  handleBackDropClose,
  activePet,
  handleOnAddWellness,
  showAddAPetScreen,
  isLoggedIn,
  handleAddPetClick,
  handleGoToCheckoutClick,
  handleOnDeclineWellness,
  checkout = false,
}) {
  function getTopMessage() {
    let message = '';
    if (checkout) {
      message = `Editing ${activePet.petName}'s Wellness Plan`;
    } else if (activePet.hasWellness) {
      message = `${activePet?.petName}'s Wellness Plan Added to Cart`;
    } else if (!showAddAPetScreen) {
      message = `${activePet?.petName}'s Insurance Added to Cart`;
    }
    return message;
  }

  return (
    <SlideOutPanel
      classes={{ container: styles.slideOutContainer }}
      show={isSlideOpen}
      onClose={handleBackDropClose}
    >
      <div className={styles.notifyMessage}>
        <NotifyMessage
          icon={checkout ? null : '/assets/images/iconImages/check-scout-navy.svg'}
          message={getTopMessage()}
        />
      </div>

      <div className={styles.slideOutContentWellness}>
        <div className={styles.slideOutBodyWellness}>
          {(activePet.hasWellness || showAddAPetScreen) && !isLoggedIn ? (
            <div>
              <img
                src={'/assets/images/circle-cat-dog.png'}
                className={styles.topIconDogCat}
                alt=''
              />

              <div className={styles.newPetBody}>
                <span className={classNames(Typography.h3, styles.newPetBodyTitle)}>
                  Want to add another pet?
                </span>

                <Body1 className={styles.newPetBodyText}>
                  We have a pawsome 10% discount for covering multiple fur babies.
                </Body1>
              </div>

              <div className={styles.newPetButtonSection}>
                <button
                  data-testid='add-a-pet'
                  className={classNames(ButtonStyles.primary, styles.btnStyle)}
                  onClick={handleAddPetClick}
                >
                  Add Another Pet
                </button>

                <button
                  data-testid='no-add-pet'
                  className={classNames(ButtonStyles.secondary, styles.btnStyle)}
                  onClick={handleGoToCheckoutClick}
                >
                  No Thanks - Go to Checkout
                </button>
              </div>
            </div>
          ) : (
            <>
              <WellnessPicker
                activePet={activePet}
                onAdd={handleOnAddWellness}
                onRemove={handleOnDeclineWellness}
                checkout={checkout}
              />
              <div className={styles.faqDiv}>
                <div className={styles.blueCircleWellness}>
                  <KeyboardArrowDown className={styles.arrowIcon} />
                </div>
                <WellnessFAQ />
                <LegalBody className={styles.sampleAgreement}>
                  {'For more, see our '}
                  <Link
                    href={`${WWW_URL}/sample-plan`}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    <span
                      className={classNames(Typography.legalBody, styles.linkFont)}
                    >
                      sample customer agreement
                    </span>
                  </Link>
                </LegalBody>
              </div>
            </>
          )}
        </div>
      </div>
    </SlideOutPanel>
  );
}

WellnessSlideOutPanel.propTypes = {
  isSlideOpen: PropTypes.bool,
  handleBackDropClose: PropTypes.func,
  activePet: PropTypes.object,
  handleOnAddWellness: PropTypes.func,
  showAddAPetScreen: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  handleAddPetClick: PropTypes.func,
  handleGoToCheckoutClick: PropTypes.func,
  handleOnDeclineWellness: PropTypes.func,
  checkout: PropTypes.bool,
};

export default WellnessSlideOutPanel;

import { Redirect, Route } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { setIsLoggedIn } from '@pumpkincare/shared';
import {
  ErrorPage,
  MaintenanceFrame,
  MaintenancePage,
} from '@pumpkincare/shared/pages';
import { customSignInToCognito } from '@pumpkincare/user';

import CheckYourEmail from '../check-your-email';
import { Checkout } from '../checkout';
import Hdyhau from '../hdyhau';
import { QuoteFlow } from '../quotes';
import Teams from '../teams';
import { VetCollection } from '../vet';
import Page from './view/page';
import PlanPage from './view/plan-page';
import Paths from './paths';

function Routes() {
  const { outageFlag } = useFlags();

  return (
    <>
      {outageFlag && outageFlag?.type === 'outage' ? (
        <Redirect to={Paths.Maintenance} />
      ) : null}

      <Route path={Paths.Teams}>
        <Page>
          <Teams />
        </Page>
      </Route>

      <Route path={'/'} exact>
        <Redirect to={Paths.QuoteFlow} />
      </Route>

      <Route path={Paths.Error}>
        <Page>
          <ErrorPage />
        </Page>
      </Route>

      <Route path={Paths.Maintenance}>
        <Page>
          <MaintenancePage />
        </Page>
      </Route>

      <Route path={Paths.MaintenanceFrame}>
        <Page isNavBarVisible={false} isFooterVisible={false}>
          <MaintenanceFrame />
        </Page>
      </Route>

      <Route path={Paths.VetCollection}>
        <Page>
          <VetCollection />
        </Page>
      </Route>

      <Route
        path={Paths.Hdyhau}
        render={props => {
          const urlSearchParams = new URLSearchParams(props.location.search);
          const email = urlSearchParams.get('email');
          const partner = urlSearchParams.get('partner')?.toLowerCase();
          const token = urlSearchParams.get('token');

          return partner === 'petmeds' && email && token ? (
            <Page
              onMount={() => {
                customSignInToCognito(email, token).then(() => {
                  setIsLoggedIn(true);

                  props.history.replace(
                    Paths.VetCollection +
                      '?' +
                      new URLSearchParams({ token }).toString()
                  );
                });
              }}
            />
          ) : (
            <Page>
              <Hdyhau />
            </Page>
          );
        }}
      ></Route>

      <Route path={Paths.CheckYourEmail}>
        <Page>
          <CheckYourEmail />
        </Page>
      </Route>

      <Route path={Paths.Checkout}>
        <Page>
          <Checkout />
        </Page>
      </Route>

      <Route
        path={Paths.CheckoutDeepLink}
        exact={true}
        render={props => {
          return (
            <Redirect
              to={{ pathname: Paths.Checkout, search: props.location.search }}
            />
          );
        }}
      />

      <Route path={Paths.PlanSelection} exact={true}>
        <PlanPage />
      </Route>

      <Route
        path={Paths.PlanSelectionDeepLink}
        exact={true}
        render={props => {
          return (
            <Redirect
              to={{ pathname: Paths.PlanSelection, search: props.location.search }}
            />
          );
        }}
      />

      <Route path={Paths.QuoteFlow}>
        <Page>
          <QuoteFlow />
        </Page>
      </Route>
    </>
  );
}

export default Routes;

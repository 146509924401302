import { useQuery } from 'react-query';

import { getCookie } from '@pumpkincare/shared';

import { useQuotesPetOrder } from './context/quotes-provider';
import { WELLNESS_QUOTE_ID_COOKIE_NAME } from './constants';
import { getWellnessQuoteById } from './wellness-quote-service';

export const WELLNESS_QUOTE_QUERY = 'wellness-quote';

export const getWellnessQuotePets = wellnessQuote => wellnessQuote.pets;

export function useWellnessQuote({
  wellnessQuoteId = getCookie(WELLNESS_QUOTE_ID_COOKIE_NAME),
  select,
  onSuccess,
} = {}) {
  const { setAllPetsOrders } = useQuotesPetOrder();
  return useQuery(
    [WELLNESS_QUOTE_QUERY, wellnessQuoteId],

    ({ signal }) =>
      getWellnessQuoteById({ wellnessQuoteId, signal }).then(wellnessQuote => {
        setAllPetsOrders(wellnessQuote.pets);
        return wellnessQuote;
      }),

    {
      enabled: !!wellnessQuoteId,

      placeholderData: {
        email: '',
        firstName: '',
        id: wellnessQuoteId,
        isRegistered: false,
        lastName: '',
        partner: '',
        pets: [],
        policyZipcode: '',
        policyState: '',
        status: '',
        trackingId: '',
        transactionFee: 0,
        vet: {},
      },

      retry: 3,
      select,
      onSuccess,
    }
  );
}

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wellness-faq_linkFont_2673a339 {\n  color: var(--bluesCluesBlue);\n}\n\n.wellness-faq_faqTitle_f6622752 {\n  margin: 16px auto 32px;\n}\n", "",{"version":3,"sources":["webpack://src/plans/view/ecomm-view-v2/wellness-faq/wellness-faq.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".linkFont {\n  color: var(--bluesCluesBlue);\n}\n\n.faqTitle {\n  margin: 16px auto 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkFont": "wellness-faq_linkFont_2673a339",
	"faqTitle": "wellness-faq_faqTitle_f6622752"
};
export default ___CSS_LOADER_EXPORT___;

import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import {
  getQuoteActivePet,
  getQuoteCompletePets,
  getQuotePets,
  getQuotePolicyState,
  getWellnessQuotePets,
  useQuote,
  useQuotesActiveId,
  useWellnessQuote,
} from '@pumpkincare/quotes';
import {
  formatCurrency,
  getIsCompletePet,
  getIsLoggedIn,
} from '@pumpkincare/shared';
import { LoaderButton } from '@pumpkincare/shared/ui';
import { getUserIsChargedAnnually, useUssr } from '@pumpkincare/user';

import {
  setUpdatedQuoteBillingField,
  setUpdatedQuotePaymentField,
  setUpdatedQuoteShippingField,
} from '../../../quotes';
import {
  getQuotesBillingAddress,
  getQuotesShippingAddress,
  getShippingSameAsBilling,
} from '../../../quotes/selectors';
import {
  useErrors,
  useErrorsVisibilitySetter,
  useIsChargedAnnuallyValue,
} from '../checkout-provider';
import { isFieldFilledOut, isValidCustomerName } from './checkout-utils';
import useSubmitCheckout from './use-submit-checkout';

function PurchaseButton({ grandTotal, classes }) {
  const { punks2110CaliforniaModelLaw } = useFlags();
  const isLoggedIn = getIsLoggedIn();
  const { data: userData } = useUssr();
  const { data: quoteData } = useQuote();
  const pets = isLoggedIn
    ? getQuotePets(quoteData)
    : getQuoteCompletePets(quoteData);
  const { activeId } = useQuotesActiveId();
  const activePet = getQuoteActivePet(quoteData)(activeId);

  const isCAModelLaw =
    punks2110CaliforniaModelLaw && getQuotePolicyState(quoteData) === 'CA';
  const { data: wellnessQuoteData } = useWellnessQuote();
  const wellnessPets = getWellnessQuotePets(wellnessQuoteData);
  const hasWellnessQuotePet = !!wellnessPets?.length;

  const dispatch = useDispatch();
  const isShippingSameAsBilling = useSelector(getShippingSameAsBilling);
  const billingAddress = useSelector(getQuotesBillingAddress);
  const shippingAddress = useSelector(getQuotesShippingAddress);
  const { submit, isLoading } = useSubmitCheckout();

  const errors = useErrors();
  const setErrorsVisibility = useErrorsVisibilitySetter();

  const isLoggedUserAnnuallyCharged = getUserIsChargedAnnually(userData);
  const isLocalAnnuallyCharged = useIsChargedAnnuallyValue();

  const isChargedAnnually = getIsLoggedIn()
    ? isLoggedUserAnnuallyCharged
    : isLocalAnnuallyCharged;
  const frequency = isChargedAnnually ? 'year' : 'month';

  const total = formatCurrency(grandTotal);
  const checkoutPets = isLoggedIn ? [activePet] : pets.filter(getIsCompletePet);

  let buttonLabel =
    checkoutPets.length > 1
      ? `Purchase ${checkoutPets.length} Plans: Pay ${total} Today`
      : `Purchase Plan: Pay ${total} / ${frequency} `;

  if (isCAModelLaw && hasWellnessQuotePet) {
    buttonLabel = `Purchase: Pay ${total} Today`;
  }

  function validateField(
    fieldType,
    addressSection,
    setAddressFieldAction,
    sectionName = ''
  ) {
    const fieldValidation =
      fieldType === 'firstName' || fieldType === 'lastName'
        ? isValidCustomerName
        : isFieldFilledOut;

    if (!fieldValidation(addressSection[fieldType].value.trim())) {
      updateFieldErrorData(
        addressSection[fieldType],
        setAddressFieldAction,
        sectionName
      );
    } else {
      removeFieldValidationError(addressSection[fieldType], sectionName);
    }
  }

  function removeFieldValidationError(field, sectionName) {
    //Ignoring section name because these fields are unique on error validation
    if (field.id === 'phone') {
      sectionName = '';
    }

    //Checking a specific error field for each type of section - Billing and Shipping
    const errorParam = sectionName ? `${sectionName}-${field.id}` : field.id;

    if (errors.current[errorParam]) {
      delete errors.current[errorParam];
    }
  }

  function updateFieldErrorData(field, action, sectionName) {
    dispatch(
      action({ field: field.id, error: true, valid: false, value: field.value })
    );

    //Ignoring section name because these fields are unique on error validation
    if (field.id === 'phone' || field.id === 'nameShownOnCard') {
      sectionName = '';
    }

    //Creating a specific error field for each type of section - Billing and Shipping
    const errorParam = sectionName ? `${sectionName}-${field.id}` : field.id;

    errors.current = {
      ...errors.current,
      [errorParam]: `Please enter a valid ${sectionName} ${field.label}`,
    };
    cardValidation();
  }

  function cardValidation() {
    Object.keys(errors.current).forEach(field => {
      if (
        ['nameShownOnCard', 'cardNumber', 'expirationDate', 'cvc'].includes(field)
      ) {
        dispatch(
          setUpdatedQuotePaymentField({
            field: field,
            error: true,
            empty: true,
            complete: false,
          })
        );
      }
    });
  }

  function cleanBannerErrors() {
    setErrorsVisibility(false);

    Object.keys(errors.current).forEach(field => {
      //removing only shipping and billing errors due the default errors
      if (
        ['billing', 'shipping'].some(section =>
          errors.current[field].includes(section)
        )
      ) {
        delete errors.current[field];
      }
    });
  }

  function handleSubmitClick(event) {
    event.preventDefault();

    cleanBannerErrors();

    if (isLoggedIn === false) {
      Object.keys(shippingAddress).forEach(fieldType => {
        if (fieldType !== 'address2')
          validateField(
            fieldType,
            shippingAddress,
            setUpdatedQuoteShippingField,
            'shipping'
          );
      });

      if (
        shippingAddress['firstName'].value.trim() === 'Pet' &&
        shippingAddress['lastName'].value.trim() === 'Parent'
      ) {
        updateFieldErrorData(
          shippingAddress['firstName'],
          setUpdatedQuoteShippingField,
          'shipping'
        );
        updateFieldErrorData(
          shippingAddress['lastName'],
          setUpdatedQuoteShippingField,
          'shipping'
        );
      }

      if (!isShippingSameAsBilling) {
        Object.keys(billingAddress).forEach(fieldType => {
          if (
            fieldType !== 'address2' &&
            fieldType !== 'firstName' &&
            fieldType !== 'lastName'
          )
            validateField(
              fieldType,
              billingAddress,
              setUpdatedQuoteBillingField,
              'billing'
            );
        });
      } else {
        validateField(
          shippingAddress.phone.id,
          shippingAddress,
          setUpdatedQuoteShippingField
        );
      }
    }

    if (Object.values(errors.current).length > 0) {
      setTimeout(() => {
        setErrorsVisibility(true);
      }, 50);
    } else {
      submit();
    }
  }

  return (
    <LoaderButton
      color='primaryLarge'
      onClick={handleSubmitClick}
      classes={classes}
      isLoading={isLoading}
      data-testid={'purchase-button'}
      type={'submit'}
    >
      {buttonLabel}
    </LoaderButton>
  );
}

PurchaseButton.propTypes = {
  grandTotal: PropTypes.number,
  classes: PropTypes.object,
};

export default PurchaseButton;

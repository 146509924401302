import { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Body2, ButtonStyles, Typography } from '@pumpkincare/shared/ui';

import WellnessCard from '../wellness-card/wellness-card';

import styles from './wellness-picker.css';

function WellnessPicker({ activePet, onAdd, onRemove, checkout }) {
  const isEssential = activePet?.tier?.includes('essential');
  const [renderedTier, setRenderedTier] = useState(
    isEssential === checkout ? 'premium' : 'essential'
  );
  const [isUserModified, setIsUserModified] = useState(false);
  useEffect(() => {
    if (!isUserModified) {
      setRenderedTier(isEssential === checkout ? 'premium' : 'essential');
    }
  }, [checkout, isEssential, isUserModified]);
  const petSpecies = activePet?.petBreedSpecies.toLowerCase();
  function getSubtitle() {
    let subtitle;
    if (checkout) {
      subtitle = `Add a wellness plan for ${activePet.petName}`;
    } else {
      subtitle = `Add an optional wellness plan for ${
        activePet?.petName || 'your pet'
      }. Pumpkin Wellness Club isn't insurance, but helps you get ahead of health issues by offering refunds for crucial wellness care.`;
    }
    return subtitle;
  }

  function getBottomButtonText() {
    let bottomButtonText = 'No, thanks';
    if (checkout) {
      bottomButtonText = isEssential ? 'Keep Essential Plan' : 'Keep Premium Plan';
    }
    return bottomButtonText;
  }

  function handleUserChange(tier) {
    return () => {
      setIsUserModified(true);
      setRenderedTier(tier);
    };
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h5 className={classNames(Typography.h5, styles.title)}>
          Unlock savings on routine care
        </h5>
      </div>
      <Body2 className={styles.subtitle}>{getSubtitle()}</Body2>
      <div className={styles.iconContainer}>
        <img
          className={styles.icon}
          src={'/assets/images/iconImages/wellness_club_logo.svg'}
          alt={'Pumpkin Wellness Club Logo'}
        />
      </div>
      <div className={styles.body}>
        <div className={styles.mobileContainer}>
          <div className={styles.mobileUpperContainer}>
            <div style={{ width: '50%', cursor: 'pointer' }}>
              <button
                className={classNames(styles.planButton, {
                  [styles.activeButtonText]: renderedTier === 'essential',
                })}
                onClick={handleUserChange('essential')}
                data-testid='essential-wellness-tab-button'
              >
                Essential Plan
              </button>
              <div
                style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
              >
                <img
                  alt=''
                  className={classNames(styles.tabIndicator, {
                    [styles.activeTab]: renderedTier === 'essential',
                  })}
                  src={'/assets/images/iconImages/wellness-active-indicator.png'}
                />
              </div>
            </div>

            <div className={styles.vDivider}>&nbsp; </div>
            <div style={{ width: '50%', cursor: 'pointer' }}>
              <button
                className={classNames(styles.planButton, {
                  [styles.activeButtonText]: renderedTier === 'premium',
                })}
                onClick={handleUserChange('premium')}
                data-testid='premium-wellness-tab-button'
              >
                Premium Plan
              </button>
              <div
                style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
              >
                <img
                  className={classNames(styles.tabIndicator, {
                    [styles.activeTab]: renderedTier === 'premium',
                  })}
                  alt=''
                  src={'/assets/images/iconImages/wellness-active-indicator.png'}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.mobileDetail}>
          <WellnessCard
            renderedTier={renderedTier}
            checkout={checkout}
            isEssential={isEssential}
            petSpecies={petSpecies}
            onAdd={onAdd}
          />
        </div>
        <div className={styles.desktopContainer}>
          <div>
            <h4 className={styles.desktopHeader}>Essential Plan</h4>
            <WellnessCard
              onAdd={onAdd}
              checkout={checkout}
              isEssential={isEssential}
              petSpecies={petSpecies}
              renderedTier={'essential'}
            />
          </div>

          <div>
            <h4 className={styles.desktopHeader}>Premium Plan</h4>

            <WellnessCard
              renderedTier={'premium'}
              checkout={checkout}
              isEssential={isEssential}
              petSpecies={petSpecies}
              onAdd={onAdd}
            />
          </div>
        </div>
      </div>
      <button
        className={classNames(ButtonStyles.secondary, styles.declineButton)}
        onClick={onRemove}
        data-testid='no-wellness'
      >
        {getBottomButtonText()}
      </button>
    </div>
  );
}

WellnessPicker.defaultProps = {
  activePet: {},
  onAdd: () => {},
  onRemove: () => {},
  checkout: false,
};

WellnessPicker.propTypes = {
  activePet: PropTypes.object,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  checkout: PropTypes.bool,
};

export default WellnessPicker;

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ButtonStyles, LegalBody, Superscript1 } from '@pumpkincare/shared/ui';
import { WELLNESS_PLANS } from '@pumpkincare/user';

import styles from './wellness-card.css';

function WellnessCard({ renderedTier, isEssential, checkout, petSpecies, onAdd }) {
  const selectedTier = isEssential ? 'essential' : 'premium';
  function getButtonText() {
    let buttonText;
    if (checkout) {
      if (selectedTier === renderedTier) {
        buttonText = (
          <div className={styles.selectedText}>
            <img
              src={'/assets/images/iconImages/check-scout-navy.svg'}
              alt={'Check'}
              width={24}
              height={24}
            />
            {`Selected - $${WELLNESS_PLANS[petSpecies][selectedTier].price}/month`}
          </div>
        );
      } else {
        buttonText = (
          <>
            Switch to {WELLNESS_PLANS[petSpecies][renderedTier].capital} - $
            {WELLNESS_PLANS[petSpecies][renderedTier].price}/month
          </>
        );
      }
    } else {
      buttonText = (
        <>
          Add {WELLNESS_PLANS[petSpecies][renderedTier].capital} Plan - $
          {WELLNESS_PLANS[petSpecies][renderedTier].price}/month
        </>
      );
    }
    return buttonText;
  }
  return (
    <div className={styles.root}>
      <div className={styles.cardContainer}>
        <div className={styles.header}>
          <LegalBody isBold style={{ textAlign: 'left' }}>
            Reimbursable Vet Services
          </LegalBody>
          <LegalBody isBold style={{ textAlign: 'right' }}>
            Yearly Limit
          </LegalBody>
        </div>

        {WELLNESS_PLANS[petSpecies][renderedTier].plans.map(item => (
          <div key={item.description} className={styles.wellnessEntitlements}>
            <LegalBody style={{ textAlign: 'left' }}>{item.description}</LegalBody>
            <LegalBody style={{ textAlign: 'right' }}>{item.limit}</LegalBody>
          </div>
        ))}
      </div>
      <div className={styles.freebiesContainer}>
        <div className={styles.headerWithImage}>
          <LegalBody isBold style={{ textAlign: 'left' }}>
            Freebies for Club Members
          </LegalBody>
          <img
            className={styles.treatJar}
            src={'/assets/images/iconImages/treat-jar.svg'}
            alt={''}
          />
        </div>
        <div className={styles.wellnessBenefits}>
          <LegalBody style={{ textAlign: 'left' }}>
            Unlimited PawSupport 24/7 Pet Health Helpline
          </LegalBody>
          <LegalBody style={{ textAlign: 'right' }}>Valued at $100+*</LegalBody>
        </div>
        <div className={styles.wellnessBenefits}>
          <LegalBody style={{ textAlign: 'left' }}>
            Access to Discounts on Top Pet Brands
          </LegalBody>
          <LegalBody style={{ textAlign: 'right' }}>Valued at $100+*</LegalBody>
        </div>
      </div>
      <div className={styles.totalValuesContainer}>
        <div>
          <h4 className={styles.totalValue}>
            {WELLNESS_PLANS[petSpecies][renderedTier].values.total}
          </h4>
          <div style={{ maxWidth: '86px', display: 'inline-flex' }}>
            <Superscript1 style={{ lineHeight: '12px' }}>
              Total Plan Value
            </Superscript1>
          </div>
        </div>
        <div>
          <img
            className={styles.minusSymbol}
            alt='Minus symbol'
            src={'/assets/images/iconImages/minus-icon.svg'}
          />
        </div>
        <div>
          <h4 className={styles.discounts}>
            {WELLNESS_PLANS[petSpecies][renderedTier].values.annualFee}
          </h4>
          <div style={{ maxWidth: '100px', display: 'inline-flex' }}>
            <Superscript1 style={{ lineHeight: '12px' }}>
              Annual Membership Fees
            </Superscript1>
          </div>
        </div>

        <img
          className={styles.equalsSymbol}
          alt='Equals symbol'
          src={'/assets/images/iconImages/equals-icon.svg'}
        />
        <div>
          <h4 className={styles.yearlySavings}>
            {WELLNESS_PLANS[petSpecies][renderedTier].values.yearlySavings}
          </h4>
          <div style={{ maxWidth: '76px', display: 'inline-flex' }}>
            <Superscript1 className={styles.yearlySavingsCaption}>
              Yearly Savings!
            </Superscript1>
          </div>
        </div>
      </div>

      <div className={styles.addButtonDiv}>
        <button
          className={classNames(ButtonStyles.primary, styles.addButton)}
          onClick={() => onAdd(renderedTier)}
          data-testid={`select-wellness-${renderedTier}`}
          disabled={renderedTier === selectedTier && checkout}
        >
          {getButtonText()}
        </button>
      </div>
    </div>
  );
}

WellnessCard.defaultProps = {
  onAdd: () => {},
  petSpecies: 'dog',
  isEssential: true,
  renderedTier: 'essential',
  checkout: false,
};

WellnessCard.propTypes = {
  checkout: PropTypes.bool,
  renderedTier: PropTypes.string,
  isEssential: PropTypes.bool,
  petSpecies: PropTypes.string,
  onAdd: PropTypes.func,
};

export default WellnessCard;

import classNames from 'classnames';

import { WWW_URL } from '@pumpkincare/config';
import {
  CONTACT_WELLNESS_EMAIL,
  CONTACT_WELLNESS_EMAIL_LINK,
  FORMATTED_WELLNESS_PHONE_NUMBER,
  WELLNESS_PHONE_NUMBER_LINK,
} from '@pumpkincare/shared';
import { Body1, Body2, FAQSet, Link, Typography } from '@pumpkincare/shared/ui';

import styles from './wellness-faq.css';

export default function WellnessFAQ() {
  const wellnessAcceptVet = {
    question: <span>Will the Pumpkin Wellness Club accept my vet?</span>,

    answer: (
      <Body2 style={{ textAlign: 'left' }}>
        As a Pumpkin Wellness Club member, you aren’t limited to a “network” of
        health care providers. Since you get paid back directly by the Pumpkin
        Wellness Club, your pet is free to receive routine preventive care from any
        licensed veterinarian you choose in the US or Canada!
      </Body2>
    ),
  };

  const howWellnessWork = {
    question: <span>How does the Pumpkin Wellness Club work?</span>,

    answer: (
      <>
        <Body2 style={{ textAlign: 'left' }}>
          The Pumpkin Wellness Club is not insurance, but a standalone product
          designed to help you afford the routine preventive care your pet needs to
          stay healthy. Pick either our Essential Wellness Plan or Premium Wellness
          Plan and book your pet’s next wellness visit. The Pumpkin Wellness Club
          will refund you up to the annual limit for each veterinary service covered
          in your plan. You will also receive access to credits and discounts from
          top pet brands throughout the year!
        </Body2>
        <br />
        <Body2 style={{ textAlign: 'left' }}>
          Here’s how your Pumpkin Wellness Club Plan works:
        </Body2>
        <ul style={{ paddingLeft: '24px', textAlign: 'left' }}>
          <li>
            Take your pet for a wellness visit and receive any (or all) services
            covered in your pet’s Essential or Premium Wellness Plan. Pay as usual
            when you leave.
          </li>
          <li>
            Log in to your account online to file a Pumpkin Wellness Club claim and
            upload a copy of your itemized vet bill (also called an invoice at the
            vet).
          </li>
          <li>
            The Pumpkin Wellness Club will process your claim quickly and reimburse
            you via direct deposit or check – up to the yearly limit for services
            received.
          </li>
        </ul>
        <Body2 style={{ textAlign: 'left' }}>
          Note: You have the option to get paid back for any covered vet services
          that your pet received up to 14 days before joining the Pumpkin Wellness
          Club.
        </Body2>
      </>
    ),
  };

  const wellnessCoverings = {
    question: (
      <span>Does the Pumpkin Wellness Club cover accidents & illnesses?</span>
    ),

    answer: (
      <>
        <Body2 style={{ textAlign: 'left' }}>
          The Pumpkin Wellness Club is not a pet insurance product and does not cover
          accidents or illnesses. The Pumpkin Wellness Club pays you back for routine
          preventive care that your vet recommends for your pet to prevent diseases,
          detect parasites and illnesses early, and stay healthy year-round. Take a
          look at our{' '}
          <Link
            href={`${WWW_URL}/sample-plan`}
            rel='noopener noreferrer'
            target='_blank'
          >
            <span className={classNames(Typography.body2, styles.linkFont)}>
              membership agreement
            </span>
          </Link>{' '}
          to learn more.
        </Body2>
        <br />
        <Body2 style={{ textAlign: 'left' }}>
          Interested in accident & illness coverage?{' '}
          <Link href={`${WWW_URL}`} rel='noopener noreferrer' target='_blank'>
            <span className={classNames(Typography.body2, styles.linkFont)}>
              Click here to learn more about Pumpkin Pet Insurance plans
            </span>
          </Link>
        </Body2>
      </>
    ),
  };

  const wellnessWhereToUse = {
    question: <span>When can I start using my Pumpkin Wellness Club benefits?</span>,

    answer: (
      <Body2 style={{ textAlign: 'left' }}>
        Your Pumpkin Wellness Club membership benefits start the day you join! You
        can also file a Pumpkin Wellness Club claim for any covered veterinary
        services that your pet received up to 14 days prior to joining.
      </Body2>
    ),
  };

  const wellnessCancellation = {
    question: <span>Can I cancel my Pumpkin Wellness Club membership?</span>,

    answer: (
      <>
        <Body2 style={{ textAlign: 'left' }}>
          Yes, you may cancel your Pumpkin Wellness Club membership by calling us at{' '}
          <a href={WELLNESS_PHONE_NUMBER_LINK} style={{ textDecoration: 'none' }}>
            {FORMATTED_WELLNESS_PHONE_NUMBER}
          </a>{' '}
          or emailing your cancellation request to{' '}
          <a style={{ textDecoration: 'none' }} href={CONTACT_WELLNESS_EMAIL_LINK}>
            {CONTACT_WELLNESS_EMAIL}
          </a>
          {''}. However, whether you receive a refund or owe outstanding fees will
          depend on a few different factors. Please find the scenario below that
          applies to you for more details.
        </Body2>
        <br />
        <Body1 style={{ textAlign: 'left' }}>Monthly Paying Members</Body1>
        <br />
        <Body2 style={{ textAlign: 'left' }}>
          I have not received reimbursement for any Pumpkin Wellness Club membership
          benefits.
        </Body2>
        <ul style={{ paddingLeft: '24px', textAlign: 'left' }}>
          <li>
            Cancel in the first 30 days after the beginning of the initial or renewal
            term and get a full refund of paid fees to you with no obligation to pay
            any future monthly fees.
          </li>
          <li>
            Cancel more than 30 days after the beginning of the initial or renewal
            term and get a pro-rated refund of only your current month’s paid fees
            (based on the number of days left in the month) with no obligation to pay
            any future monthly fees.
          </li>
        </ul>
        <Body2 style={{ textAlign: 'left' }}>
          I have received reimbursement for one or more Pumpkin Wellness Club
          membership benefits.
        </Body2>
        <ul style={{ paddingLeft: '24px', textAlign: 'left' }}>
          <li>
            Cancel any time after the beginning of the initial or renewal term, and
            you will owe outstanding fees equal to the difference of the total amount
            paid to you for any membership benefit(s) during the term year and the
            total amount paid by you in monthly membership fees during the same term
            year. After a one-time charge to your card on file for any outstanding
            fees, you will have no obligation to pay any future monthly fees. There
            will be no refund to you if the amount of outstanding fees is a negative
            number.
          </li>
        </ul>
        <br />

        <Body1 style={{ textAlign: 'left' }}>Annual Paying Members</Body1>
        <br />
        <Body2 style={{ textAlign: 'left' }}>
          I have not received reimbursement for any Pumpkin Wellness Club membership
          benefits.
        </Body2>
        <ul style={{ paddingLeft: '24px', textAlign: 'left' }}>
          <li>
            Cancel in the first 30 days after the beginning of the initial or renewal
            term and get a full refund of paid fees to you with no obligation to pay
            any future annual fees.
          </li>
          <li>
            Cancel more than 30 days after the beginning of the initial or renewal
            term and no refund will be provided.
          </li>
        </ul>
        <Body2 style={{ textAlign: 'left' }}>
          I have received reimbursement for one or more Pumpkin Wellness Club
          membership benefits.
        </Body2>
        <ul style={{ paddingLeft: '24px', textAlign: 'left' }}>
          <li>
            Cancel any time after the beginning of the initial or renewal term and no
            refund will be provided.
          </li>
        </ul>
        <Body2 style={{ textAlign: 'left' }}>
          See our{' '}
          <Link
            href={`${WWW_URL}/sample-plan`}
            rel='noopener noreferrer'
            target='_blank'
          >
            <span className={classNames(Typography.body2, styles.linkFont)}>
              Membership Agreement
            </span>
          </Link>{' '}
          for full cancellation & refund terms
        </Body2>
      </>
    ),
  };

  const allQuestions = [
    wellnessAcceptVet,
    howWellnessWork,
    wellnessCoverings,
    wellnessWhereToUse,
    wellnessCancellation,
  ];

  return (
    <>
      <h4 className={styles.faqTitle}>Wellness Club FAQs</h4>

      <FAQSet questionSet={allQuestions} />
    </>
  );
}

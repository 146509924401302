import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import {
  analyticsTrack,
  CHECKOUT,
  GAQuoteFlowClickToEditPetInfo,
} from '@pumpkincare/analytics';
import {
  getProductHasPrevent,
  usePlan,
  useProductsByState,
} from '@pumpkincare/plans';
import {
  getQuotePolicyState,
  getQuoteVetId,
  useDeleteQuotePet,
  useMutateCreateWellnessQuote,
  useMutatePetPlan,
  useMutateWellnessQuotePet,
  useQuote,
  useQuotesActiveId,
} from '@pumpkincare/quotes';
import { formatCurrency, formatPetAge, getIsLoggedIn } from '@pumpkincare/shared';
import { Body2, LegalBody, RouteLink, Typography } from '@pumpkincare/shared/ui';
import { getPetHasActiveWellness, getUserId, useUssr } from '@pumpkincare/user';

import { Paths } from '../../../../../../app-shell';
import { fetchQuoteById, setCheckoutPetAsActive } from '../../../../../../quotes';
import { getQuotesId } from '../../../../../../quotes/selectors';
import Prevent from './prevent';
import Protect from './protect';
import Wellness from './wellness';
import WellnessSlideOutPanel from './wellness-slide-out-panel';

import styles from './pet-summary-panel.css';

function createWellnessQuotePayload(quoteData, activePet, planType) {
  const { firstName, lastName, email, policyZipcode } = quoteData;
  const { id, petBreedCode, petGender, petName, petAge, petBreedSpecies } =
    activePet;

  return {
    first_name: firstName,
    last_name: lastName,
    email: email,
    zipcode: policyZipcode,
    saw_experiment: '0',
    saw_partner: 'pumpkin',
    quote_pets: [
      {
        id: id,
        breed_code: petBreedCode,
        pet_age: petAge,
        pet_gender: petGender,
        pet_name: petName,
        species: petBreedSpecies?.toLowerCase() || '',
        tier: `${planType}.${petBreedSpecies?.toLowerCase() || ''}`,
      },
    ],
  };
}

function PetSummaryPanel({
  pet,
  wellnessPets,
  setWellnessPets,
  petCost,
  isMultiPet,
  isUserChargedAnnually,
}) {
  const { punks2110CaliforniaModelLaw, punks2119HideWellnessCtaCheckout } =
    useFlags();
  const [isSlideOpen, setIsSlideOpen] = useState(false);
  const wellnessPet = wellnessPets.find(
    wellnessPet =>
      wellnessPet.petName === pet.petName &&
      wellnessPet.petBreedSpecies === pet.petBreedSpecies &&
      wellnessPet.petGender === pet.petGender
  );
  const { setActiveId } = useQuotesActiveId();

  const dispatch = useDispatch();
  const quoteId = useSelector(getQuotesId);

  const { mutateAsync: deleteQuotePet } = useDeleteQuotePet();

  const { data: userData } = useUssr();
  const hasActiveWellness = getPetHasActiveWellness(userData, pet);
  const isWellnessPet = wellnessPet !== undefined;

  const { mutateAsync: mutatePetPlan } = useMutatePetPlan();
  const { mutateAsync: mutateCreateWellnessQuote } = useMutateCreateWellnessQuote();
  const { mutateAsync: patchWellnessQuotePet } = useMutateWellnessQuotePet();
  const { data: quoteData } = useQuote();
  const vetId = getQuoteVetId(quoteData);
  const userId = getUserId(userData);
  const { data: planData } = usePlan(
    pet?.petAge,
    pet?.petBreedSpecies,
    userId,
    vetId
  );

  const policyState = getQuotePolicyState(quoteData);
  const { data: productsByStateData } = useProductsByState(policyState);
  const productHasPrevent = getProductHasPrevent(productsByStateData);

  const isCAModelLaw = punks2110CaliforniaModelLaw && policyState === 'CA';
  const { petBreedName, petAge, plan } = pet;
  const priceMultiplier = isUserChargedAnnually ? 12 : 1;
  const billingFrequencySuffix = isUserChargedAnnually ? '/ yr' : '/ mo';
  const isLoggedIn = getIsLoggedIn();

  let {
    price_before_discount: fullPrice,
    price_after_discount: priceAfterDiscount,
  } = petCost;

  const isThereDiscount = petCost.discounts.length > 0;

  let preventPrice = parseFloat(plan?.cost ?? 0) * priceMultiplier;

  const total = preventPrice + fullPrice;
  const totalWithDiscount = isThereDiscount ? preventPrice + priceAfterDiscount : 0;

  const protectFinalPrice = isThereDiscount
    ? formatCurrency(priceAfterDiscount)
    : formatCurrency(fullPrice);

  const fullPriceClassname = totalWithDiscount ? styles.beforeDiscount : null;

  function handleEditClick() {
    dispatch(setCheckoutPetAsActive(pet.id));
    setActiveId(pet.id);

    GAQuoteFlowClickToEditPetInfo('Checkout');
  }

  function handleOnAddWellness(planType) {
    analyticsTrack({
      category: 'Add Wellness Plan',
      event: `Click Add ${planType} Plan`,
      label: `Add Wellness Plan`,
    });

    const payload = createWellnessQuotePayload(quoteData, pet, planType);

    mutateCreateWellnessQuote(payload).then(() => {
      setIsSlideOpen(false);
    });

    window.scrollTo(0, 0);
  }

  function handleEditWellnessClick() {
    setIsSlideOpen(true);
  }

  function handleOnEditWellness(planType) {
    analyticsTrack({
      category: 'Edit Wellness Plan',
      event: `Click Edit ${planType} Plan`,
      label: `Edit Wellness Plan`,
    });

    const tier = `${planType}.${pet.petBreedSpecies?.toLowerCase() || ''}`;

    const quotePetId = isWellnessPet ? wellnessPet.id : pet.id;
    patchWellnessQuotePet({
      id: quotePetId,
      tier: tier,
    }).then(pet => {
      const updatedPets = wellnessPets.map(wellnessPet => {
        if (wellnessPet.id !== pet.id) {
          return wellnessPet;
        } else {
          return {
            ...wellnessPet,
            tier: pet.tier,
          };
        }
      });
      setWellnessPets(updatedPets);
      setIsSlideOpen(false);
    });

    window.scrollTo(0, 0);
  }

  function handleDeleteConfirmClick() {
    deleteQuotePet({ quotePetId: pet.id }).then(
      () => dispatch(fetchQuoteById(quoteId)) // updating redux quote, can be removed after quotes are converted to hooks)
    );
  }

  function handlePreventChange() {
    analyticsTrack({
      event: pet.hasPrevent ? 'Remove prevent from plan' : 'Add prevent to plan',
      category: CHECKOUT,
    });

    mutatePetPlan({
      pet: { ...pet, hasPrevent: !pet.hasPrevent },
      plan: planData,
    });
  }

  function handleBackDropClose() {
    setIsSlideOpen(false);
  }

  function getAddPepModule() {
    return !plan && productHasPrevent && !hasActiveWellness && !isCAModelLaw ? (
      <div className={styles.addContainer}>
        <span className={classNames(Typography.legalBody, styles.boxText)}>
          Add Preventive Essentials to help prevent health issues before they start
        </span>

        <button className={styles.addSection} onClick={() => handlePreventChange()}>
          <span className={classNames(Typography.h6, styles.adSignal)}>&#43;</span>

          <span>Add</span>
        </button>
      </div>
    ) : null;
  }

  function getAddWellnessModule() {
    return !hasActiveWellness && isCAModelLaw && !isWellnessPet ? (
      <div className={styles.addContainer}>
        <div className={styles.wellnessLogo}>
          <img
            src={'/assets/images/iconImages/wellness_club_logo.svg'}
            alt={'Wellness Club Logo'}
          />
        </div>
        <div className={styles.textArea}>
          <span className={classNames(Typography.legalBody, styles.boxText)}>
            Add Pumpkin Wellness Club to help prevent health issues before they start
          </span>

          <button className={styles.addSection} onClick={handleEditWellnessClick}>
            <span className={classNames(Typography.h6, styles.adSignal)}>&#43;</span>

            <span>Add</span>
          </button>
        </div>
      </div>
    ) : null;
  }

  return (
    <div style={{ margin: '25px 0px' }}>
      {!hasActiveWellness && isCAModelLaw ? (
        <WellnessSlideOutPanel
          activePet={isWellnessPet ? wellnessPet : pet}
          isSlideOpen={isSlideOpen}
          handleBackDropClose={handleBackDropClose}
          handleOnAddWellness={
            wellnessPets.length === 0 ? handleOnAddWellness : handleOnEditWellness
          }
          showAddAPetScreen={false}
          isLoggedIn={isLoggedIn}
          checkout={isWellnessPet}
          handleOnDeclineWellness={handleBackDropClose}
        />
      ) : null}
      <div className={styles.divFlexRow}>
        <div className={styles.nameSection}>
          <Body2 isBold>
            {pet.petName}
            {isCAModelLaw ? null : "'s Plan"}
          </Body2>

          {isMultiPet ? (
            <button
              onClick={handleDeleteConfirmClick}
              className={classNames(Typography.link, styles.linkContainer)}
            >
              <img alt='' src='/assets/images/iconImages/remove-pet-x-circle.svg' />
              {'Remove pet'}
            </button>
          ) : null}
        </div>

        {isCAModelLaw ? null : (
          <div>
            {totalWithDiscount ? (
              <Body2 isBold className={styles.withDiscount}>
                {`${formatCurrency(totalWithDiscount)} ${billingFrequencySuffix}`}
              </Body2>
            ) : null}

            <div
              className={classNames(
                Typography.body2,
                Typography.bold,
                fullPriceClassname
              )}
            >
              {`${formatCurrency(total)} ${billingFrequencySuffix}`}
            </div>
          </div>
        )}
      </div>

      <div className={styles.containerLegacy}>
        <div className={styles.innerContainerLegacy}>
          <img
            src={
              pet.petBreedSpecies === 'Dog'
                ? '/assets/images/iconImages/dog_icon.svg'
                : '/assets/images/iconImages/cat_icon.svg'
            }
            alt=''
            style={{ marginLeft: '-10px' }}
            height={55}
            width={55}
          />

          <div className={styles.divFlexColumn}>
            <div style={{ marginBottom: '8px' }}>
              <LegalBody className={styles.petInfo}>Breed</LegalBody>

              <LegalBody className={styles.petInfo}>{petBreedName} </LegalBody>
            </div>

            <div>
              <LegalBody className={styles.petInfo}>Age</LegalBody>

              <LegalBody className={styles.petInfo}>
                {formatPetAge(petAge)}
              </LegalBody>
            </div>
          </div>
        </div>

        <Protect
          isLoggedIn={isLoggedIn}
          discount={petCost}
          isThereDiscount={isThereDiscount}
          fullPrice={`${formatCurrency(fullPrice)} ${billingFrequencySuffix}`}
          finalPrice={`${protectFinalPrice} ${billingFrequencySuffix}`}
          pet={pet}
        />

        <RouteLink
          to={Paths.PlanSelection}
          className={classNames(
            styles.linkContainer,
            styles.linkEdit,
            Typography.link
          )}
          onClick={handleEditClick}
        >
          <img alt='' src='/assets/images/iconImages/edit.png' />
          {'Edit Plan'}
        </RouteLink>

        {isWellnessPet ? (
          <>
            <Wellness
              pet={wellnessPet}
              isLoggedIn={isLoggedIn}
              priceMultiplier={priceMultiplier}
              billingFrequencySuffix={billingFrequencySuffix}
            />

            <button
              className={classNames(
                styles.linkContainer,
                styles.linkEdit,
                Typography.link
              )}
              onClick={handleEditWellnessClick}
            >
              <img alt='' src='/assets/images/iconImages/edit.png' />
              {'Edit Plan'}
            </button>
          </>
        ) : null}

        {plan ? (
          <>
            <Prevent
              petId={pet.id}
              species={pet.petBreedSpecies}
              planPrice={`${preventPrice.toFixed(2)} ${billingFrequencySuffix}`}
              pet={pet}
            />

            <button
              onClick={() => handlePreventChange()}
              className={classNames(styles.removeSection, Typography.link)}
            >
              <img alt='' src='/assets/images/iconImages/remove-pet-x-circle.svg' />
              {'Remove from plan'}
            </button>
          </>
        ) : null}

        {getAddPepModule()}
        {punks2119HideWellnessCtaCheckout ? null : getAddWellnessModule()}
      </div>
    </div>
  );
}

PetSummaryPanel.propTypes = {
  pet: PropTypes.object,
  petCost: PropTypes.object,
  isMultiPet: PropTypes.bool,
  wellnessPets: PropTypes.array,
  setWellnessPets: PropTypes.func,
  isUserChargedAnnually: PropTypes.bool,
};

export default PetSummaryPanel;

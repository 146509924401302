import PropTypes from 'prop-types';

import { formatCurrency } from '@pumpkincare/shared';
import { CollapsiblePanel, LegalBody } from '@pumpkincare/shared/ui';
import { WELLNESS_PLANS } from '@pumpkincare/user';

import styles from './wellness.css';

function Wellness({ pet, priceMultiplier, billingFrequencySuffix }) {
  const tier = pet.tier.includes('essential') ? 'essential' : 'premium';
  const tierText = tier === 'essential' ? 'Essentials' : 'Premium';
  const petSpecies = pet.petBreedSpecies.toLowerCase();
  const price = WELLNESS_PLANS[petSpecies][tier].price;
  return (
    <div style={{ marginTop: '16px' }}>
      <div className={styles.insurancePreventTitleDiv}>
        <LegalBody isBold className={styles.titleClassname}>
          Pumpkin Wellness Club -<br />
          {tierText}
        </LegalBody>

        <LegalBody isBold className={styles.insurancePreventTitlePrice}>
          {formatCurrency(price * priceMultiplier)} {billingFrequencySuffix}
        </LegalBody>
      </div>

      <div className={styles.verticalLine}>
        <CollapsiblePanel
          defaultOpen={false}
          classes={{
            title: styles.titleClassname,
          }}
          title={
            <LegalBody isBold className={styles.detailsColor}>
              View Details
            </LegalBody>
          }
        >
          <div>
            {WELLNESS_PLANS[petSpecies][tier].plans.map(item => (
              <LegalBody key={item.description} className={styles.LegalBodyMargin}>
                {item.description} - {item.limit}/yr
              </LegalBody>
            ))}
            <LegalBody className={styles.LegalBodyMargin}>
              Unlimited PawSupport 24/7 Pet Health Helpline
            </LegalBody>
            <LegalBody className={styles.LegalBodyMargin}>
              Access to Discounts on Top Pet Brands
            </LegalBody>
          </div>
        </CollapsiblePanel>
      </div>
    </div>
  );
}

Wellness.propTypes = {
  pet: PropTypes.shape({
    tier: PropTypes.string,
    petBreedSpecies: PropTypes.string,
  }),
  priceMultiplier: PropTypes.number,
  billingFrequencySuffix: PropTypes.string,
};

export default Wellness;

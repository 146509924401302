const processEnv = process.env ?? {};
// default to {} for styleguidist

export const {
  APP_NAME,
  ENVIRONMENT,
  BASE_DOMAIN,
  SUBDOMAIN_SUFFIX,
  HOME_URL,
  PUMPKIN_ASSETS_URL,
  BASE_DOMAIN_REVIEW,

  GA_CODE,
  AW_CODE,
  FACEBOOK_PIXEL_ID,

  LAUNCH_DARKLEY_KEY,

  SEGMENT_KEY,
  SEGMENT_SRC_BASE,

  ONETRUST_ID,
  SPRIG_ID,
  INTERCOM_ID,

  POLICY_BASE_API,
  PERSONALIZATION_BASE_API,
  CLOUDFLARE_WORKER_API,
  BREED_DEFINITIONS_API,
  STATES_API,
  PROGRAM_RESOLVER_API,

  STRIPE_API_KEY,
  NOOP_STRIPE_API_KEY,

  AWS_IDENTITY_POOL_ID,
  AWS_REGION,
  AWS_USER_POOL_ID,
  AWS_USER_POOL_WEB_CLIENT_ID,
  WELLNESS_BASE_API,
  XRAY_APP_NAME,
} = processEnv;

export const MC_URL = `https://member${SUBDOMAIN_SUFFIX || ''}.${
  ENVIRONMENT === 'local' ? 'pumpkin.dev' : BASE_DOMAIN
}`;

export const PORTAL_URL = `https://portal${SUBDOMAIN_SUFFIX || ''}.${
  ENVIRONMENT === 'local' ? 'pumpkin.dev' : BASE_DOMAIN
}`;

export const QF_URL = `https://get${SUBDOMAIN_SUFFIX || ''}.${
  ENVIRONMENT === 'local' ? 'pumpkin.dev' : BASE_DOMAIN
}`;

export const WELLNESS_URL = `https://wellness${SUBDOMAIN_SUFFIX || ''}.${
  ENVIRONMENT === 'local' ? 'pumpkin.dev' : BASE_DOMAIN
}`;

export const WWW_URL = `https://www.${
  ENVIRONMENT === 'local' ? 'pumpkin.dev' : BASE_DOMAIN
}`;

export const BLOG_URL = `${WWW_URL}/blog`;

export const XRAY_ENABLED = Boolean(Number(processEnv.XRAY_ENABLED));

export const WELLNESS_SWIFTER_URL = `https://${
  ENVIRONMENT === 'production' ? 'wellness.pumpkin.care' : 'app.uat-pk.swifter.dev'
}`;

import axios from 'axios';

import { WELLNESS_BASE_API } from '@pumpkincare/config';
import { responseDataBody } from '@pumpkincare/shared';

import { transformPetRequest, transformQuote } from './utils/quote-utils';

export function createWellnessQuote(payload) {
  return axios
    .post(`${WELLNESS_BASE_API}/wellness-quotes`, payload)
    .then(responseDataBody);
}

export function getWellnessQuoteById({
  wellnessQuoteId,
  signal,
  onSuccess = () => {},
}) {
  const url = `${WELLNESS_BASE_API}/wellness-quotes/${wellnessQuoteId}`;
  return axios
    .get(url, { signal })
    .then(resp => {
      onSuccess(resp.data.body);
      return returnResponseBody(resp);
    })
    .then(wellnessQuote => {
      return transformQuote(wellnessQuote);
    });
}

function returnResponseBody(response) {
  return response.data.body;
}

export function createWellnessQuotePet({ wellnessQuoteId, pet, tier }) {
  const url = `${WELLNESS_BASE_API}/wellness-quotes/${wellnessQuoteId}/pets`;

  const payload = transformPetRequest(pet);
  payload.tier = tier;
  return axios.post(url, payload).then(returnResponseBody);
}

export function patchWellnessQuotePet({ wellnessQuoteId, pet }) {
  const url = `${WELLNESS_BASE_API}/wellness-quotes/${wellnessQuoteId}/pets/${pet.id}`;

  return axios
    .patch(url, {
      tier: pet.tier,
    })
    .then(returnResponseBody);
}

export const WELLNESS_PLANS = {
  dog: {
    essential: {
      plans: [
        { description: 'Wellness Exams', limit: '$100' },
        { description: 'Vaccinations', limit: '$100' },
        { description: 'Routine Bloodwork', limit: '$50' },
        { description: 'Fecal Test', limit: '$50' },
      ],
      values: {
        total: '$500',
        annualFee: '$239',
        yearlySavings: '$260+',
      },
      price: 19.95,
      capital: 'Essential',
    },
    premium: {
      plans: [
        { description: 'Wellness Exams', limit: '$100' },
        { description: 'Vaccinations', limit: '$150' },
        { description: 'Routine Bloodwork', limit: '$100' },
        { description: 'Fecal Test', limit: '$50' },
        { description: 'Tick/Flea/Heartworm Prevention', limit: '$150' },
        {
          description: 'Spay/Neuter Procedure OR Routine Dental Cleaning',
          limit: '$150',
        },
      ],
      values: {
        total: '$900',
        annualFee: '$539',
        yearlySavings: '$360+',
      },
      price: 44.95,
      capital: 'Premium',
    },
  },
  cat: {
    essential: {
      plans: [
        { description: 'Wellness Exams', limit: '$100' },
        { description: 'Vaccinations', limit: '$100' },
        { description: 'Fecal Test', limit: '$40' },
      ],
      values: {
        total: '$440',
        annualFee: '$239',
        yearlySavings: '$200+',
      },
      price: 14.95,
      capital: 'Essential',
    },
    premium: {
      plans: [
        { description: 'Wellness Exams', limit: '$100' },
        { description: 'Vaccinations', limit: '$150' },
        { description: 'Routine Bloodwork', limit: '$75' },
        { description: 'Fecal Test', limit: '$40' },
        { description: 'Tick/Flea/Heartworm Prevention', limit: '$150' },
        {
          description: 'Spay/Neuter Procedure OR Routine Dental Cleaning',
          limit: '$150',
        },
      ],
      values: {
        total: '$865',
        annualFee: '$419',
        yearlySavings: '$395+',
      },
      price: 34.95,
      capital: 'Premium',
    },
  },
};

import { useMutation, useQueryClient } from 'react-query';

import { getCookie, setCookie } from '@pumpkincare/shared';

import { transformPet } from './utils/quote-utils';
import { WELLNESS_QUOTE_ID_COOKIE_NAME } from './constants';
import { WELLNESS_QUOTE_QUERY } from './wellness-quote-query';
import {
  createWellnessQuote,
  createWellnessQuotePet,
  patchWellnessQuotePet,
} from './wellness-quote-service';

export function useMutateCreateWellnessQuote() {
  const queryClient = useQueryClient();

  return useMutation(payload => createWellnessQuote(payload), {
    onMutate: async () => {
      await queryClient.cancelQueries([WELLNESS_QUOTE_QUERY]);

      const prevData = queryClient.getQueryData([WELLNESS_QUOTE_QUERY]);

      return { prevData };
    },
    onSuccess: data => {
      const wellnessQuoteId = data.id;
      setCookie(WELLNESS_QUOTE_ID_COOKIE_NAME, wellnessQuoteId);
    },
  });
}

export function useMutateWellnessQuotePet() {
  const queryClient = useQueryClient();

  return useMutation(
    pet => {
      const wellnessQuoteId = getCookie(WELLNESS_QUOTE_ID_COOKIE_NAME);
      return patchWellnessQuotePet({ wellnessQuoteId, pet }).then(transformPet);
    },

    {
      onMutate: async variables => {
        const wellnessQuoteId = getCookie(WELLNESS_QUOTE_ID_COOKIE_NAME);
        await queryClient.cancelQueries([WELLNESS_QUOTE_QUERY, wellnessQuoteId]);

        const prevData = queryClient.getQueryData([
          WELLNESS_QUOTE_QUERY,
          wellnessQuoteId,
        ]);

        queryClient.setQueryData([WELLNESS_QUOTE_QUERY, wellnessQuoteId], {
          ...prevData,

          pets: prevData?.pets.map(pet =>
            pet.id === variables.id
              ? {
                  ...pet,
                  ...variables.pet,
                }
              : pet
          ),
        });

        return { prevData };
      },
    }
  );
}

export function useCreateWellnessQuotePet(
  wellnessQuoteId = getCookie(WELLNESS_QUOTE_ID_COOKIE_NAME)
) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ pet, tier }) =>
      createWellnessQuotePet({ wellnessQuoteId, pet, tier }).then(transformPet),

    {
      onMutate: async variables => {
        await queryClient.cancelQueries([WELLNESS_QUOTE_QUERY, wellnessQuoteId]);

        const prevData = queryClient.getQueryData([
          WELLNESS_QUOTE_QUERY,
          wellnessQuoteId,
        ]);

        queryClient.setQueryData([WELLNESS_QUOTE_QUERY, wellnessQuoteId], {
          ...prevData,

          pets: prevData.pets.map(pet =>
            pet.id === variables.id
              ? {
                  ...pet,
                  ...variables.pet,
                }
              : pet
          ),
        });

        return { prevData };
      },
    }
  );
}

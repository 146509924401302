import { createContext, useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const CHECKOUT_PROVIDER_ERROR = new Error(
  'The hook must be used within a CheckoutProvider'
);

const Errors = createContext();
const ErrorsVisibilityValue = createContext();
const ErrorsVisibilitySetter = createContext();

const TotalsValue = createContext();
const TotalsSetter = createContext();

const IsChargedAnnuallyValue = createContext();
const IsChargedAnnuallyValueSetter = createContext();

const PaperLessValue = createContext();
const PaperLessSetter = createContext();

function CheckoutProvider({ children, initialErrors }) {
  const errors = useRef(initialErrors);
  const [errorsVisibility, setErrorsVisibility] = useState(false);
  const [isChargedAnnually, setIsChargedAnnually] = useState(false);
  const [totals, setTotals] = useState({
    insuranceTotal: 0,
    preventTotal: 0,
    wellnessTotal: 0,
    total: 0,
    grandTotal: 0,
  });

  const [paperLess, setPaperLess] = useState({
    termsVersion: '',
    isPaperLess: false,
  });

  return (
    <Errors.Provider value={errors}>
      <ErrorsVisibilityValue.Provider value={errorsVisibility}>
        <ErrorsVisibilitySetter.Provider value={setErrorsVisibility}>
          <TotalsValue.Provider value={totals}>
            <TotalsSetter.Provider value={setTotals}>
              <IsChargedAnnuallyValue.Provider value={isChargedAnnually}>
                <IsChargedAnnuallyValueSetter.Provider value={setIsChargedAnnually}>
                  <PaperLessValue.Provider value={paperLess}>
                    <PaperLessSetter.Provider value={setPaperLess}>
                      {children}
                    </PaperLessSetter.Provider>
                  </PaperLessValue.Provider>
                </IsChargedAnnuallyValueSetter.Provider>
              </IsChargedAnnuallyValue.Provider>
            </TotalsSetter.Provider>
          </TotalsValue.Provider>
        </ErrorsVisibilitySetter.Provider>
      </ErrorsVisibilityValue.Provider>
    </Errors.Provider>
  );
}

CheckoutProvider.propTypes = {
  children: PropTypes.node,
  initialErrors: PropTypes.object,
};

export default CheckoutProvider;

export function useErrors() {
  const context = useContext(Errors);

  if (context === undefined) {
    throw CHECKOUT_PROVIDER_ERROR;
  }

  return context;
}

export function useErrorsVisibilityValue() {
  const context = useContext(ErrorsVisibilityValue);

  if (context === undefined) {
    throw CHECKOUT_PROVIDER_ERROR;
  }

  return context;
}

export function useErrorsVisibilitySetter() {
  const context = useContext(ErrorsVisibilitySetter);

  if (context === undefined) {
    throw CHECKOUT_PROVIDER_ERROR;
  }

  return context;
}

export function useErrorsVisibility() {
  return [useErrorsVisibilityValue(), useErrorsVisibilitySetter()];
}

export function useTotalsValue() {
  const context = useContext(TotalsValue);

  if (context === undefined) {
    throw CHECKOUT_PROVIDER_ERROR;
  }

  return context;
}

export function useTotalsSetter() {
  const context = useContext(TotalsSetter);

  if (context === undefined) {
    throw CHECKOUT_PROVIDER_ERROR;
  }

  return context;
}

export function useIsChargedAnnuallyValue() {
  const context = useContext(IsChargedAnnuallyValue);

  if (context === undefined) {
    throw CHECKOUT_PROVIDER_ERROR;
  }
  return context;
}

export function useIsChargedAnnuallySetter() {
  const context = useContext(IsChargedAnnuallyValueSetter);

  if (context === undefined) {
    throw CHECKOUT_PROVIDER_ERROR;
  }

  return context;
}

export function usePaperLessValue() {
  const context = useContext(PaperLessValue);

  if (context === undefined) {
    throw CHECKOUT_PROVIDER_ERROR;
  }

  return context;
}

export function usePaperLessSetter() {
  const context = useContext(PaperLessSetter);

  if (context === undefined) {
    throw CHECKOUT_PROVIDER_ERROR;
  }

  return context;
}

export function useTotals() {
  return [useTotalsValue(), useTotalsSetter()];
}

import { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { UNLIMITED_ANNUAL_LIMIT } from '@pumpkincare/plans';
import { formatCurrency } from '@pumpkincare/shared';
import { LegalBody, Radio } from '@pumpkincare/shared/ui';

import InsuranceModal from '../insurance-modal';
import Levers from '../levers';

import styles from './bundle-levers.css';

import heartBadge from './heart-badge.svg';
import iconEdit from './icon-edit.svg';
import petsChalk from './pets-chalk-board.png';

function BundleLevers({ petPlanData, partner, onChange, pet, bundleMapping }) {
  const {
    activeValues,
    insurancePicker: { availableLevers, recommendations, prices },
    submitButton: { selectedPolicyPrice },
  } = petPlanData;

  const bundles = [
    {
      name: 'justInCase',
      title: 'JUST IN CASE',
      recurrency: '/mo',
      values: getItemValues(bundleMapping.cheaper),
    },
    {
      name: 'peaceOfMind',
      title: 'PEACE OF MIND',
      recurrency: '/mo',
      isPopular: true,
      values: getItemValues(bundleMapping.preselected),
    },
    {
      name: 'whateverItTakes',
      title: 'WHATEVER IT TAKES',
      recurrency: '/mo',
      values: getItemValues(bundleMapping.higher_cost),
    },
  ];
  const options = [
    ...bundles,
    {
      title: 'CUSTOM PLAN',
      icon: iconEdit,
      recurrency: '/mo',
      isCustom: true,
      values: {
        price: selectedPolicyPrice,
        deductible: activeValues.deductible,
        limit: activeValues.annual_limit,
        copay: activeValues.copay,
      },
    },
  ];

  const defaultIndex = bundles.findIndex(bundle => {
    return (
      bundle.values.deductible === activeValues.deductible &&
      bundle.values.limit === activeValues.annual_limit &&
      `${100 - Number(bundle.values.reimbursement.replace(/\W/g, ''))}` ===
        activeValues.copay
    );
  });
  const isDefaultCustom = defaultIndex === -1;

  const [selectedIndex, setSelectedIndex] = useState(
    isDefaultCustom ? options.length - 1 : defaultIndex
  );
  const [isCustomSelected, setIsCustomSelected] = useState(isDefaultCustom);
  const [customPrice, setCustomPrice] = useState(selectedPolicyPrice);
  const [customLeversValues, setCustomLeversValues] = useState(activeValues);
  const [isLeverActive, setLeverActive] = useState(true);

  const bundlesModal = {
    justInCase: {
      title: (
        <>
          The <b>Just In Case</b> plan
        </>
      ),
      sectionItems: [
        {
          title: 'Why choose it?',
          copy: (
            <>
              Known for its great value, the ‘Just In Case’ plan is for people who
              want assurance that if unexpected curve balls come their pet’s way,
              they can afford crucial accident & illness care and get paid back for a
              majority of their eligible bills.
            </>
          ),
        },
      ],
      footer: {
        items: [
          {
            title: 'What is an annual limit?',
            copy: 'The max amount of eligible vet bills your pet insurance plan can reimburse you for each policy year.',
          },
          {
            title: 'What is an annual deductible?',
            copy: (
              <>
                The amount you pay upfront towards eligible vet bills each policy
                year <b>before</b> your cash back kicks in.
              </>
            ),
          },
        ],
        img: petsChalk,
      },
    },

    peaceOfMind: {
      title: (
        <>
          The <b>Peace of Mind</b> plan
        </>
      ),
      sectionItems: [
        {
          title: 'Why choose it?',
          copy: (
            <>
              Our most popular, the 'Piece of Mind' plan is for people who want the
              comfort of knowing that if everyday accidents & illnesses pop up, they
              can provide the vet care recommended and start getting cash back
              quickly, thanks to a lower annual deductible.
            </>
          ),
        },
      ],
      footer: {
        items: [
          {
            title: 'What is an annual limit?',
            copy: 'The max amount of eligible vet bills your pet insurance plan can reimburse you for each policy year.',
          },
          {
            title: 'What is an annual deductible?',
            copy: (
              <>
                The amount you pay upfront towards eligible vet bills each policy
                year <b>before</b> your cash back kicks in.
              </>
            ),
          },
        ],
        img: petsChalk,
      },
    },

    whateverItTakes: {
      title: (
        <>
          The <b>Whatever It Takes</b> plan
        </>
      ),
      sectionItems: [
        {
          title: 'Why choose it?',
          copy: (
            <>
              With its standout unlimited coverage, the 'Whatever It Takes' plan is
              for people who want to be 100% certain that if their pet ever faces a
              life-threatening accident or illness, they can say ‘yes’ to the best,
              most advanced vet care, whatever the cost.
            </>
          ),
        },
      ],
      footer: {
        items: [
          {
            title: 'What is an annual limit?',
            copy: 'The max amount of eligible vet bills your pet insurance plan can reimburse you for each policy year.',
          },
          {
            title: 'What is an annual deductible?',
            copy: (
              <>
                The amount you pay upfront towards eligible vet bills each policy
                year <b>before</b> your cash back kicks in.
              </>
            ),
          },
        ],
        img: petsChalk,
      },
    },
  };

  function getItemValues(bundle) {
    const price = prices.Copay10[bundle.annual_limit][bundle.deductible];

    const deductible = availableLevers.deductibles.find(
      item => item.id === bundle.deductible
    )?.option.value;

    const limit = availableLevers.limits.find(
      item => item.id === bundle.annual_limit
    )?.option.value;

    const optionValueReimbursement =
      availableLevers.copays?.find(
        item => item.option.value === bundle.reimbursement
      )?.option.value ?? '10';

    const reimbursement = optionValueReimbursement === '10' ? '90%' : '80%';

    return {
      price,
      deductible,
      limit,
      reimbursement,
    };
  }

  function getBundlePrices() {
    const priceCheaper =
      prices.Copay10[bundleMapping.cheaper.annual_limit][
        bundleMapping.cheaper.deductible
      ];
    const priceSelected =
      prices.Copay10[bundleMapping.preselected.annual_limit][
        bundleMapping.preselected.deductible
      ];
    const priceHigher =
      prices.Copay10[bundleMapping.higher_cost.annual_limit][
        bundleMapping.higher_cost.deductible
      ];

    return [priceCheaper, priceSelected, priceHigher];
  }

  function handleOptionClick(event, option, index) {
    event.stopPropagation();
    const target = event.target;

    const { deductible, limit, copay } = option.values;
    const currentValues = {
      annual_limit: limit,
      deductible,
      copay: copay,
    };

    if (isCustomSelected === false) {
      setCustomLeversValues(currentValues);
      setCustomPrice(option.values.price);
    }

    if (target.id === 'modal-icon' || target.id === 'close-modal') {
      // return if modal icon or close modal button was clicked
      return;
    }
    setSelectedIndex(index);

    if (!option.isCustom) {
      setLeverActive(false);
      onChange(currentValues);
    } else {
      if (!isLeverActive) {
        onChange(customLeversValues);
      }
      setLeverActive(true);
      setIsCustomSelected(true);
    }
  }

  function getOptionState(option, index) {
    // Check if the option is custom and no option is selected
    if (option.isCustom && selectedIndex === null) {
      const bundlePrices = getBundlePrices();
      return !bundlePrices.includes(parseFloat(option.values.price));
    } else {
      // Check if the current option is selected by index
      const isSelectedByIndex = selectedIndex === index;

      // Check if no option is selected, but deductible and annual limit match
      const isSelectedByValues =
        selectedIndex === null &&
        option.values.deductible === activeValues.deductible &&
        option.values.limit === activeValues.annual_limit &&
        option.values.copay === activeValues.copay;

      // Return true if either index or values match the selection criteria
      return isSelectedByIndex || isSelectedByValues;
    }
  }

  function handleLeverChanges(values, e) {
    e.stopPropagation();

    const customPrice =
      prices[`Copay${values.copay}`][`Limit${values.annual_limit}`][
        `Deductible${values.deductible}`
      ];

    setCustomLeversValues(values);
    setCustomPrice(customPrice);
    onChange(values);

    // copy from handleOptionChange, but we stop propagation so we don't invoke handleOptionChange
    setSelectedIndex(3);
    setIsCustomSelected(true);
    setLeverActive(true);
  }

  return (
    <div className={styles.container}>
      {options.map((option, index) => {
        const isOptionSelected = getOptionState(option, index);

        const leverItemClass = classNames(styles.leverItem, {
          [styles.leverItemSelected]: isOptionSelected,
        });

        const itemTitleClass = classNames(styles.itemTitle, {
          [styles.itemTitleSelected]: selectedIndex === index,
        });

        const recommendedSectionDesktopClass = classNames(
          styles.recommendedSection,
          styles.showDesktop
        );

        const recommendedSectionMobileClass = classNames(
          styles.recommendedSection,
          styles.showMobile
        );

        return (
          <div
            key={index}
            className={leverItemClass}
            onClick={e => handleOptionClick(e, option, index)}
            role='radio'
          >
            <div className={styles.toggleSection}>
              <Radio
                variant='light'
                value={true}
                classes={{ root: styles.leverRadio }}
                checked={isOptionSelected}
                small
              />
            </div>

            <div className={styles.itemContentWrapper}>
              <div className={styles.itemContentSection}>
                <div className={styles.itemContent}>
                  <div className={styles.itemHeader}>
                    {option.icon ? (
                      <img src={option.icon} alt='' className={styles.iconEdit} />
                    ) : null}

                    <LegalBody isBold className={itemTitleClass}>
                      {option.title}
                    </LegalBody>
                    {!option.isCustom ? (
                      <InsuranceModal
                        modalData={bundlesModal[option.name] ?? {}}
                        classes={{
                          modalTitle: styles.modalTitle,
                          modalIcon: styles.modalIcon,
                        }}
                      />
                    ) : null}
                    {option.isPopular ? (
                      <div className={recommendedSectionDesktopClass}>
                        <div className={styles.recommended}>
                          <img src={heartBadge} alt='' className={styles.badge} />
                          <LegalBody isBold className={styles.recommendedTitle}>
                            MOST POPULAR
                          </LegalBody>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  {option.values?.price && !option.isCustom ? (
                    <div className={styles.priceSection}>
                      <h4 className={styles.price}>
                        {
                          formatCurrency(option.values.price, {
                            areCents: false,
                          }).split('.')[0]
                        }
                      </h4>
                      <LegalBody isBold>
                        {
                          formatCurrency(option.values.price, {
                            areCents: false,
                          }).split('.')[1]
                        }
                      </LegalBody>
                      <LegalBody className={styles.recurrency}>
                        {option.recurrency}
                      </LegalBody>
                    </div>
                  ) : null}
                  {!option.isCustom ? (
                    <div className={styles.itemFooter}>
                      <LegalBody>
                        {option.values?.limit
                          ? `Annual Limit: ${
                              option.values.limit === UNLIMITED_ANNUAL_LIMIT
                                ? UNLIMITED_ANNUAL_LIMIT
                                : formatCurrency(option.values.limit, {
                                    areCents: false,
                                    hideZero: true,
                                  })
                            }`
                          : null}
                      </LegalBody>

                      {option.values?.reimbursement ? (
                        <LegalBody>
                          Reimbursement: {option.values.reimbursement}
                        </LegalBody>
                      ) : null}

                      <LegalBody>
                        {option.values?.deductible
                          ? `Annual Deductible: ${formatCurrency(
                              option.values.deductible,
                              {
                                areCents: false,
                                hideZero: true,
                              }
                            )}`
                          : null}
                      </LegalBody>
                    </div>
                  ) : null}
                </div>

                <div className={recommendedSectionMobileClass}>
                  {option.isPopular ? (
                    <div className={styles.recommended}>
                      <LegalBody isBold className={styles.recommendedTitle}>
                        MOST POPULAR
                      </LegalBody>
                      <img src={heartBadge} alt='' className={styles.badge} />
                    </div>
                  ) : null}
                </div>
              </div>
              {option.isCustom && (isOptionSelected || isCustomSelected) ? (
                <div>
                  <div className={styles.priceSection}>
                    <h4 className={styles.price}>
                      {
                        formatCurrency(customPrice, {
                          areCents: false,
                        }).split('.')[0]
                      }
                    </h4>
                    <LegalBody isBold>
                      {
                        formatCurrency(customPrice, {
                          areCents: false,
                        }).split('.')[1]
                      }
                    </LegalBody>

                    <LegalBody className={styles.recurrency}>
                      {option.recurrency}
                    </LegalBody>
                  </div>
                  <Levers
                    activeValues={customLeversValues}
                    levers={availableLevers}
                    recommendations={
                      partner === 'internal-plan-recommendation'
                        ? {}
                        : recommendations
                    }
                    onChange={handleLeverChanges}
                    pet={pet}
                    isActive={isLeverActive}
                  />
                </div>
              ) : null}
            </div>
          </div>
        );
      })}
    </div>
  );
}

BundleLevers.propTypes = {
  petPlanData: PropTypes.shape({
    activeValues: PropTypes.shape({
      annual_limit: PropTypes.string,
      deductible: PropTypes.string,
      copay: PropTypes.string,
    }),
    insurancePicker: PropTypes.shape({
      availableLevers: PropTypes.shape({
        copays: PropTypes.array,
        deductibles: PropTypes.array,
        limits: PropTypes.array,
      }),
      recommendations: PropTypes.object,
      prices: PropTypes.object,
    }),
    submitButton: PropTypes.shape({
      selectedPolicyPrice: PropTypes.string,
    }),
  }),
  onChange: PropTypes.func,
  pet: PropTypes.object,
  bundleMapping: PropTypes.shape({
    cheaper: PropTypes.shape({
      annual_limit: PropTypes.string,
      deductible: PropTypes.string,
    }),
    preselected: PropTypes.shape({
      annual_limit: PropTypes.string,
      deductible: PropTypes.string,
    }),
    higher_cost: PropTypes.shape({
      annual_limit: PropTypes.string,
      deductible: PropTypes.string,
    }),
  }),
  partner: PropTypes.string,
};

BundleLevers.defaultProps = {
  petPlanData: {},
  onChange: () => {},
  pet: {},
  bundleMapping: {},
  partner: '',
};

export default BundleLevers;

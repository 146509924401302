import { getUserPets } from './user-self-query';
import { getWellnessConfig } from './wellness-config-utils';

export function getPetHasActiveWellness(userData, pet) {
  const userPets = getUserPets(userData);
  const currentUserPet = userPets.find(item => item.id === pet.existing_pet_id);
  const { active: wellnessActive } = getWellnessConfig(
    currentUserPet?.wellness || {}
  );
  return !!wellnessActive.isActive;
}

const NB_DASH = `\u2011`; //nonbreaking dash
const NB_SPACE = `\u0020`; //nonbreaking space
const PHONE_NUMBER = `18662736369`;
const WELLNESS_PHONE_NUMBER = `18662434665`;

export const PHONE_NUMBER_LINK = `tel:+${PHONE_NUMBER}`;
export const FORMATTED_PHONE_NUMBER = `1${NB_DASH}866${NB_DASH}273${NB_DASH}6369`;
export const FORMATTED_PARENTHESES_PHONE_NUMBER = `(866)${NB_SPACE}273${NB_DASH}6369`;
export const ARF_MEOW_PHONE_NUMBER = `1${NB_DASH}866${NB_DASH}ARF${NB_DASH}MEOW`;
export const PHONE_ARIA_LABEL = `Give us a call at ${ARF_MEOW_PHONE_NUMBER} also known as ${FORMATTED_PHONE_NUMBER}. Selecting this will launch your default call settings.`;
export const PHONE_HOURS = [`Mon - Fri 8am – 8pm EST`, `Sat 9am – 5pm EST`];

export const WELLNESS_PHONE_NUMBER_LINK = `tel:+${WELLNESS_PHONE_NUMBER}`;
export const FORMATTED_WELLNESS_PHONE_NUMBER = `1${NB_DASH}866${NB_DASH}243${NB_DASH}4665`;
export const FORMATTED_PARENTHESES_WELLNESS_PHONE_NUMBER = `(866)${NB_SPACE}243${NB_DASH}4665`;

export const CONTACT_EMAIL = `help@pumpkin.care`;
export const CONTACT_WELLNESS_EMAIL = `wellness@pumpkin.care`;
export const CONTACT_EMAIL_LINK = `mailto:${CONTACT_EMAIL}`;
export const CONTACT_WELLNESS_EMAIL_LINK = `mailto:${CONTACT_WELLNESS_EMAIL}`;
export const HELLO_EMAIL = `hello@pumpkin.care`;
export const HELLO_EMAIL_LINK = `mailto:${HELLO_EMAIL}`;

export const GLADLY_HOURS = `Mon - Sat 9am - 5pm EST`;
export function openGladly() {
  if (window.Gladly) {
    window.Gladly.show();
  } else {
    // eslint-disable-next-line no-console
    console.error('Gladly is not set up in this environment');
  }
}

export const OFFICE_LOCATION = '432 Park Avenue South, Floor 12, New York, NY 10016';

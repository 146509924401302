import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import {
  getProductCanUpsellPrevent,
  getProductHasPrevent,
  useProductsByState,
} from '@pumpkincare/plans';
import { getQuotePartner, getQuotePolicyState, useQuote } from '@pumpkincare/quotes';
import { DOG } from '@pumpkincare/shared';
import { ButtonStyles, Typography } from '@pumpkincare/shared/ui';
import { getPetHasActiveWellness, useUssr } from '@pumpkincare/user';

import BundleLevers from './bundle-levers';

import styles from './insurance-picker-bundle.css';

import stethoscopePng from './stethoscope.png';

function InsurancePickerBundle({
  petPlanData,
  onChange,
  buttonText,
  onAddToCart,
  pet,
  activePrice,
}) {
  const { punks2110CaliforniaModelLaw } = useFlags();
  const { data: quoteData } = useQuote();
  const partner = getQuotePartner(quoteData);

  const policyState = getQuotePolicyState(quoteData);
  const { data: productsByStateData } = useProductsByState(policyState);

  const isCAModelLaw = punks2110CaliforniaModelLaw && policyState === 'CA';

  const productHasPrevent = getProductHasPrevent(productsByStateData); //Maine
  const productCanUpsellPrevent = getProductCanUpsellPrevent(productsByStateData);

  const { data: userData } = useUssr();
  const hasWellness = getPetHasActiveWellness(userData, pet);

  const bundle_mapping = {
    cheaper: {
      name: 'Wallet Friendly',
      deductible: 'Deductible1000',
      annual_limit: pet.petBreedSpecies === DOG ? 'Limit10000' : 'Limit7000',
      reimbursement: '10',
    },
    preselected: {
      // Replacement for Recommendation
      name: 'Most Popular',
      deductible: pet.petBreedSpecies === DOG ? 'Deductible500' : 'Deductible250',
      annual_limit: pet.petBreedSpecies === DOG ? 'Limit10000' : 'Limit7000',
      reimbursement: '10',
    },
    higher_cost: {
      name: 'Above and Beyond',
      deductible: 'Deductible500',
      annual_limit: 'LimitUnlimited',
      reimbursement: '10',
    },
  };

  return (
    <div className={styles.root}>
      <div className={styles.containerBundle}>
        <div className={styles.leversContainer}>
          <BundleLevers
            petPlanData={petPlanData}
            quoteData={quoteData}
            partner={partner}
            onChange={onChange}
            pet={pet}
            bundleMapping={bundle_mapping}
          />
        </div>

        <button
          className={classNames(ButtonStyles.primaryLarge, styles.addToCartButton)}
          data-testid='select-this-plan'
          onClick={onAddToCart}
        >
          {`${buttonText} – $${activePrice} / mo`}
        </button>
      </div>

      {productHasPrevent &&
      productCanUpsellPrevent &&
      !hasWellness &&
      !isCAModelLaw ? (
        <div className={styles.wellnessCallout}>
          <div>
            <img alt='' src={stethoscopePng} className={styles.stethoscope} />
          </div>

          <div>
            <span className={Typography.legalBody}>
              <b>Want refunds on wellness care?</b>
              <br />
              Add Preventive Essentials on the next page! It's not insurance, but an
              optional wellness package you can add to your plan.
            </span>
          </div>
        </div>
      ) : null}
    </div>
  );
}
InsurancePickerBundle.defaultProps = {
  petPlanData: {},
  onChange: () => {},
  buttonText: 'Add to Cart',
  onAddToCart: () => {},
  pet: {},
  activePrice: '0.00',
};

InsurancePickerBundle.propTypes = {
  petPlanData: PropTypes.object,
  onChange: PropTypes.func,
  buttonText: PropTypes.string,
  onAddToCart: PropTypes.func,
  pet: PropTypes.object,
  activePrice: PropTypes.string,
};

export default InsurancePickerBundle;
